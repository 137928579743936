import React from 'react';
import * as styles from './InferenceResults.module.css';
import { FaChartBar, FaExpand, FaCompress } from 'react-icons/fa';

const InferenceResults = ({ predictions, isExpanded, onToggleExpand }) => {
  console.log('🎨 Rendering InferenceResults with:', predictions);
  
  const isDetection = predictions?.[0]?.hasOwnProperty('box');

  return (
    <div className={`${styles.resultsPanel} ${isExpanded ? styles.expanded : ''}`}
         style={{ zIndex: 1001 }}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <FaChartBar className={styles.icon} />
          <span>Inference Results</span>
        </div>
        <button 
          className={styles.expandButton}
          onClick={onToggleExpand}
        >
          {isExpanded ? <FaCompress /> : <FaExpand />}
        </button>
      </div>

      <div className={styles.content}>
        {predictions && predictions.length > 0 ? (
          <div className={styles.predictions}>
            {predictions
              .filter(pred => (isDetection ? pred.score > 0.25 : pred.confidence > 0.1))
              .map((pred, index) => {
                const confidence = isDetection ? pred.score : pred.confidence;
                const confidencePercent = (confidence * 100).toFixed(1);
                const confidenceColor = getConfidenceColor(confidence);
                
                return (
                  <div 
                    key={index} 
                    className={styles.predictionItem}
                    style={{
                      '--confidence-color': confidenceColor,
                      '--confidence-percent': `${confidencePercent}%`
                    }}
                  >
                    <div className={styles.predictionHeader}>
                      <span className={styles.className}>{pred.class}</span>
                      <span className={styles.confidence}>{confidencePercent}%</span>
                    </div>
                    <div className={styles.confidenceBar}>
                      <div 
                        className={styles.confidenceProgress} 
                        style={{ width: `${confidencePercent}%` }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className={styles.noResults}>
            No predictions available
          </div>
        )}
      </div>
    </div>
  );
};

function getConfidenceColor(confidence) {
  if (confidence > 0.8) return '#00F135';
  if (confidence > 0.6) return '#9EF100';
  if (confidence > 0.4) return '#F1C40F';
  return '#E74C3C';
}

export default InferenceResults; 