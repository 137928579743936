import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

class UFOGame {
    constructor(scene, drone) {
        this.scene = scene;
        this.drone = drone;
        this.score = 0;
        this.ufoModel = null;
        this.currentUFO = null;
        this.loader = new GLTFLoader();
        this.bounds = {
            minX: -50,
            maxX: 50,
            minY: 5,
            maxY: 30,
            minZ: -50,
            maxZ: 50
        };
        this.scoreDisplay = null;
        this.initialize();
    }

    async initialize() {
        // Create score display first
        this.createScoreDisplay();
        // Then load the UFO model
        await this.loadUFOModel();
    }

    createScoreDisplay() {
        const display = document.createElement('div');
        display.style.position = 'absolute';
        display.style.top = '20px';
        display.style.right = '20px';
        display.style.padding = '10px 20px';
        display.style.backgroundColor = 'rgba(5, 10, 14, 0.95)';
        display.style.color = '#00F135';
        display.style.fontFamily = 'monospace';
        display.style.fontSize = '24px';
        display.style.borderRadius = '8px';
        display.style.border = '1px solid rgba(0, 241, 53, 0.2)';
        display.style.zIndex = 1000;
        
        // Set initial score text before adding to DOM
        display.textContent = 'Score: 0';
        
        // Add to DOM
        document.body.appendChild(display);
        this.scoreDisplay = display;
    }

    updateScore(newScore) {
        if (this.scoreDisplay) {
            this.score = newScore;
            this.scoreDisplay.textContent = `Score: ${this.score}`;
        }
    }

    async loadUFOModel() {
        try {
            const gltf = await this.loader.loadAsync('/assets/models/ufo.glb');
            this.ufoModel = gltf.scene;
            
            // Set up UFO material for better visibility
            this.ufoModel.traverse((child) => {
                if (child.isMesh) {
                    child.material.emissive = new THREE.Color(0x00ff00);
                    child.material.emissiveIntensity = 0.5;
                }
            });

            // Scale the UFO to be appropriate relative to the map
            const scale = 2;
            this.ufoModel.scale.set(scale, scale, scale);

            this.spawnNewUFO();
        } catch (error) {
            console.error('Error loading UFO model:', error);
        }
    }

    getRandomPosition() {
        return new THREE.Vector3(
            THREE.MathUtils.randFloat(this.bounds.minX, this.bounds.maxX),
            THREE.MathUtils.randFloat(this.bounds.minY, this.bounds.maxY),
            THREE.MathUtils.randFloat(this.bounds.minZ, this.bounds.maxZ)
        );
    }

    spawnNewUFO() {
        if (!this.ufoModel) return;

        // If there's an existing UFO, remove it
        if (this.currentUFO) {
            this.scene.remove(this.currentUFO);
            // Clean up geometries and materials
            this.currentUFO.traverse((child) => {
                if (child.isMesh) {
                    child.geometry.dispose();
                    child.material.dispose();
                }
            });
        }

        // Create new UFO instance
        this.currentUFO = this.ufoModel.clone();
        const position = this.getRandomPosition();
        this.currentUFO.position.copy(position);
        
        // Add to scene
        this.scene.add(this.currentUFO);
    }

    checkCollision() {
        if (!this.currentUFO || !this.drone) return false;

        const ufoPosition = this.currentUFO.position;
        const dronePosition = this.drone.position;
        
        // Calculate distance between drone and UFO
        const distance = ufoPosition.distanceTo(dronePosition);
        
        // Collision threshold based on UFO size
        const collisionThreshold = 3; // Adjust based on UFO scale
        
        return distance < collisionThreshold;
    }

    update() {
        if (this.checkCollision()) {
            this.updateScore(this.score + 1);
            this.spawnNewUFO();
        }

        // Add some movement to the UFO
        if (this.currentUFO) {
            const time = Date.now() * 0.001;
            this.currentUFO.rotation.y = time * 0.5;
            // Add subtle hovering motion
            this.currentUFO.position.y += Math.sin(time * 2) * 0.01;
        }
    }

    cleanup() {
        if (this.currentUFO) {
            this.scene.remove(this.currentUFO);
            this.currentUFO.traverse((child) => {
                if (child.isMesh) {
                    child.geometry.dispose();
                    child.material.dispose();
                }
            });
        }
        if (this.scoreDisplay && this.scoreDisplay.parentNode) {
            this.scoreDisplay.parentNode.removeChild(this.scoreDisplay);
        }
    }
}

export default UFOGame; 