export const modelConfigs = {
  'MobileNetV2': {
    path: '/assets/models/mobilenetv2/mobilenetv2-12.onnx',
    labels: '/assets/models/mobilenetv2/imagenet_labels.json',
    inputSize: [224, 224],
    inputFormat: 'NCHW',
    preprocess: {
      resize: 'direct',
      normalize: {
        mean: [0.485, 0.456, 0.406],
        std: [0.229, 0.224, 0.225]
      }
    },
    postprocess: {
      type: 'classification',
      topK: 5
    }
  },
  'YOLOv10n': {
    path: '/assets/models/yolov10n/yolov10n.onnx',
    labels: '/assets/models/yolov10n/config.json',
    inputSize: [640, 640],
    preprocess: {
      resize: 'letterbox',
      rescale: {
        factor: 0.00392156862745098  // 1/255
      }
    },
    postprocess: {
      type: 'detection',
      confidenceThreshold: 0.25,
      iouThreshold: 0.45
    }
  }
}; 