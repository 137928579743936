import React, { useRef, useEffect, useContext, useState, useCallback } from 'react';
import * as THREE from 'three';
import DroneScene from '../scenes/droneScene';
import DroneControls from '../controls/droneControls';
import PhysicsEngine from '../physics/PhysicsEngine';
import {
  createPositionDisplay,
  updatePositionDisplay,
  createControlBar,
  updateControlBar,
  createCompass,
  updateCompass,
  createAxesView,
  updateAxesView,
} from '../utils/helperFunctions';
import {
  createFPVDisplay,
  captureFPVSnapshot,
  downloadImage,
  displayImage,
  runInference,
} from '../utils/fpvUtils';
import { LoadingContext } from '../context/loadingContext';
import { useSimulation } from '../context/simulationContext';
import CustomMapLoader from '../utils/customMapLoader';
import InferenceResults from './InferenceResults';

/**
 * @component Simulation
 * @description A React component that renders a 3D drone simulation using Three.js.
 * It includes a main view, First Person View (FPV), axes display, and various UI elements
 * for displaying drone status and controls.
 * 
 * @example
 * <Simulation />
 */
const Simulation = () => {
  // Reference to the main DOM container for the simulation
  const mountRef = useRef(null);
  // Reference to the Three.js scene containing the drone and environment
  const sceneRef = useRef(null);
  // Stores the timestamp of the last frame for delta time calculation
  const lastTimeRef = useRef(performance.now());
  // Track if scene is already initialized to prevent double initialization
  const isInitializedRef = useRef(false);
  // Stores the current frames per second value
  const fpsRef = useRef(0);
  // Array to store frame timestamps for FPS calculation
  const frameTimesRef = useRef([]);
  // Stores the time taken for physics calculations per frame
  const physicsTimeRef = useRef(0);
  // Stores the time taken for rendering per frame
  const renderTimeRef = useRef(0);
  // Reference to the first-person view camera
  const fpvCameraRef = useRef(null);
  // Reference to the renderer for the first-person view
  const fpvRendererRef = useRef(null);
  // Context function for adding logs to the UI
  const { addLog } = useContext(LoadingContext);
  // Context values for simulation state management
  const { simulationRef, isInferenceRunning, selectedModel } = useSimulation();
  // Reference to the physics engine instance
  const physicsEngineRef = useRef(null);
  // Reference to the drone controls handler
  const controlsRef = useRef(null);
  // Reference to the UI control bars displaying drone inputs
  const controlBarsRef = useRef(null);

  // State for horizon mode
  const [isHorizonMode, setIsHorizonMode] = useState(false);
  // State for FPV ready status
  const [fpvReady, setFpvReady] = useState(false);
  // Add state for predictions and expanded view
  const [predictions, setPredictions] = useState([]);
  const [isResultsExpanded, setIsResultsExpanded] = useState(false);

  /**
   * Starts the inference pipeline for capturing and processing FPV camera frames.
   * @returns {Function} Cleanup function to stop the pipeline
   */
  const startInferencePipeline = useCallback(() => {
    if (!fpvRendererRef.current || !fpvCameraRef.current || !sceneRef.current || !selectedModel) {
      console.error('Required refs not available for inference');
      return;
    }

    console.log('Starting inference pipeline for model:', selectedModel);
    console.log('FPV Camera position:', fpvCameraRef.current.position);
    console.log('FPV Camera rotation:', fpvCameraRef.current.rotation);
    
    const captureAndInfer = async () => {
      if (!fpvRendererRef.current || !fpvCameraRef.current) return;
      
      try {
        console.log('Attempting to capture FPV snapshot...');
        console.log('FPV Renderer dimensions:', {
          width: fpvRendererRef.current.domElement.width,
          height: fpvRendererRef.current.domElement.height
        });
        
        // Force a render before capture
        fpvRendererRef.current.render(sceneRef.current, fpvCameraRef.current);
        
        const pixelData = captureFPVSnapshot(fpvRendererRef.current);
        console.log('Captured pixel data:', {
          hasData: !!pixelData,
          length: pixelData ? pixelData.length : 0,
          isBlack: pixelData ? pixelData.every(val => val === 0) : true
        });
        
        if (pixelData) {
          const width = fpvRendererRef.current.domElement.width;
          const height = fpvRendererRef.current.domElement.height;
          const dims = [1, 3, height, width];
          console.log('Running inference with dimensions:', dims);
          
          await runInference(pixelData, dims, selectedModel);
        } else {
          console.error('Failed to capture pixel data from FPV renderer');
        }
      } catch (error) {
        console.error('Inference error:', error);
      }
    };

    // Initial capture
    captureAndInfer();

    // Set interval for continuous inference
    const intervalId = setInterval(captureAndInfer, 1000);
    return () => {
      clearInterval(intervalId);
      console.log('Inference pipeline stopped:', selectedModel);
    };
  }, [fpvRendererRef, fpvCameraRef, sceneRef, selectedModel]);

  /**
   * Resets the drone to its initial spawn position and updates the physics engine.
   * 
   * @function resetSimulation
   * @description This function performs a complete reset of the drone's position and physics state.
   * It first checks if the scene reference exists before attempting the reset to prevent errors.
   * After resetting the drone's position, it ensures the physics engine is synchronized with
   * the new position by triggering a physics update.
   * 
   * The function includes the following steps:
   * 1. Verifies scene reference exists
   * 2. Calls resetDroneToSpawn() to move drone to initial position
   * 3. If successful, updates physics engine to reflect new position
   * 4. Logs success message to UI
   * 
   * @returns {void}
   * 
   * @example
   * // Reset drone position via button click
   * <button onClick={resetSimulation}>Reset Drone</button>
   * 
   * // Reset drone position via keyboard
   * if (event.key === 'r') resetSimulation();
   * 
   * @throws {Error} Implicitly fails silently if scene reference is null
   * @safety Includes null checks for both scene and physics engine references
   */
  const resetSimulation = () => {
    if (sceneRef.current) {
      const success = sceneRef.current.resetDroneToSpawn();
      
      // Ensure physics engine is still active
      if (success && physicsEngineRef.current) {
        physicsEngineRef.current.update();
      }
      
      if (success) {
        addLog('Drone reset to initial position');
      }
    }
  };

  /**
   * @effect Main Simulation Setup
   * @description Primary effect hook that initializes and manages the entire 3D simulation environment.
   * This effect is responsible for setting up Three.js, physics, controls, and cleanup.
   * 
   * Initialization Order:
   * 1. Three.js Renderer
   * 2. Main Camera
   * 3. Drone Scene
   * 4. Drone Controls
   * 5. Physics Engine
   * 6. UI Elements (FPV, Position Display, Control Bars, Compass)
   * 7. Animation Loop
   * 
   * @dependencies {Array} [addLog, simulationRef, isInferenceRunning, selectedModel]
   * - addLog: Logger function from LoadingContext
   * - simulationRef: Reference to simulation state
   * - isInferenceRunning: Boolean flag for inference state
   * - selectedModel: Selected ML model for inference
   * 
   * @cleanup
   * - Removes event listeners
   * - Cleans up DOM elements
   * - Nullifies references
   * - Clears frame tracking arrays
   * - Removes custom map
   * 
   * @returns {Function} Cleanup function executed on component unmount
   * 
   * @example
   * useEffect(() => {
   *   // Initialization code...
   *   
   *   return () => {
   *     // Cleanup code...
   *   };
   * }, [addLog, simulationRef, isInferenceRunning, selectedModel]);
   * 
   * @throws {Error} Potential errors during scene or physics initialization
   * @safety
   * - Includes null checks before operations
   * - Handles cleanup of all created resources
   * - Manages memory through proper disposal
   * 
   * @performance
   * - Uses requestAnimationFrame for smooth animation
   * - Tracks FPS and performance metrics
   * - Manages memory through proper cleanup
   */
  useEffect(() => {
    if (!mountRef.current) return;

    // Prevent multiple initializations
    if (isInitializedRef.current) {
      console.log('🚫 Scene already initialized, skipping initialization');
      return;
    }
    isInitializedRef.current = true;

    /**
     * Initializes the Three.js renderer with advanced settings.
     * @type {THREE.WebGLRenderer}
     * 
     * Configuration:
     * - antialias: true - Enables smoother edges on 3D objects
     * - shadowMap: PCFSoftShadowMap - High-quality soft shadows
     * - size: Matches window dimensions for full-screen rendering
     * 
     * DOM Integration:
     * - Creates a WebGL canvas element
     * - Appends to mountRef (main container div)
     * - Automatically scales with window size
     * 
     * @example
     * // Creating and configuring renderer
     * const renderer = new THREE.WebGLRenderer({ antialias: true });
     * renderer.setSize(window.innerWidth, window.innerHeight);
     * renderer.shadowMap.enabled = true;
     * renderer.shadowMap.type = THREE.PCFSoftShadowMap;
     * 
     * // Attaching to DOM
     * mountRef.current.appendChild(renderer.domElement);
     * 
     * @note This renderer instance will be used for the main view.
     * The FPV (First Person View) uses a separate renderer instance.
     */
    addLog('Initializing Three.js Renderer...');
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    mountRef.current.appendChild(renderer.domElement);
    addLog('Three.js Renderer initialized.');

    /**
     * Sets up the main camera and drone scene for the simulation.
     * 
     * @type {THREE.PerspectiveCamera}
     * @type {DroneScene}
     * 
     * Camera Configuration:
     * - FOV: 75 degrees - Wide field of view for better scene visibility
     * - Aspect Ratio: Matches window dimensions
     * - Near Plane: 0.1 units - Closest visible distance
     * - Far Plane: 1000 units - Furthest visible distance
     * 
     * Camera Position:
     * - X: 0 (centered)
     * - Y: 1 (slightly above ground)
     * - Z: -3 (behind drone)
     * - Looking at origin (0,0,0)
     * 
     * Scene Setup:
     * - Creates new DroneScene instance
     * - Stores reference in sceneRef for global access
     * - Passes addLog for scene-level logging
     * 
     * @example
     * // Camera initialization
     * const camera = new THREE.PerspectiveCamera(
     *   75, // FOV
     *   window.innerWidth / window.innerHeight, // Aspect ratio
     *   0.1, // Near plane
     *   1000 // Far plane
     * );
     * 
     * @note The camera position is later updated in the animation loop
     * to follow the drone's movement
     */
    addLog('Initializing Drone Scene...');
    console.log('🚁 Creating new DroneScene instance');
    const scene = new DroneScene(addLog);
    sceneRef.current = scene;
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(0, 1, -3);
    camera.lookAt(0, 0, 0);
    addLog('Drone Scene and Camera initialized.');

    /**
     * Initializes drone controls and physics engine.
     * 
     * @type {DroneControls} Controls handler for user inputs
     * @type {PhysicsEngine} Physics simulation engine
     * 
     * Control System:
     * - Handles keyboard inputs
     * - Processes gamepad/controller inputs
     * - Maps inputs to drone commands (throttle, pitch, roll, yaw)
     * - Maintains control state between frames
     * 
     * Physics Engine:
     * - Simulates drone physics (gravity, momentum, etc.)
     * - Handles collision detection
     * - Processes aerodynamic forces
     * - Takes control inputs to calculate forces
     * 
     * Integration Flow:
     * 1. Controls capture user input
     * 2. Physics engine uses control values
     * 3. Forces are applied to drone
     * 4. Position/rotation updates are rendered
     * 
     * @example
     * // In animation loop:
     * controls.update();  // Process new inputs
     * physicsEngine.update(deltaTime);  // Apply physics
     * 
     * @note The physics engine requires control input to calculate
     * forces, which is why we pass controls to its constructor
     */
    addLog('Initializing Drone Controls...');
    const controls = new DroneControls();
    controlsRef.current = controls;
    addLog('Drone Controls initialized.');

    addLog('Initializing Physics Engine...');
    const physicsEngine = new PhysicsEngine(controls);
    physicsEngineRef.current = physicsEngine;
    addLog('Physics Engine initialized.');

    // Initialize the scene and physics engine
    scene.init().then(() => {
      addLog('Drone Scene initialized successfully.');
      physicsEngine.init(scene).then(() => {
        // Set the physics engine reference in the scene
        scene.setPhysicsEngine(physicsEngine);
        addLog('Physics Engine initialized successfully.');
      }).catch((error) => {
        addLog(`Error initializing Physics Engine: ${error.message}`);
      });
    }).catch((error) => {
      addLog(`Error initializing Drone Scene: ${error.message}`);
    });

    /**
     * Creates and sets up various UI elements for the simulation.
     */
    addLog('Creating UI Elements...');
    const positionDisplay = createPositionDisplay();
    mountRef.current.appendChild(positionDisplay.container);
    addLog('Position Display created.');

    // Initialize FPV display
    const { fpvRenderer, updateRendererSize } = createFPVDisplay(mountRef.current);
    fpvRendererRef.current = fpvRenderer;

    // Create FPV camera
    const fpvCamera = new THREE.PerspectiveCamera(
      90,
      fpvRenderer.domElement.width / fpvRenderer.domElement.height,
      0.1,
      1000
    );
    fpvCameraRef.current = fpvCamera;

    // Signal that FPV is ready
    setFpvReady(true);

    /**
     * Creates and sets up the axes view for showing drone orientation.
     */
    addLog('Creating Axes View...');
    const axesView = createAxesView(mountRef.current);
    addLog('Axes View created.');

    /**
     * Creates control display for showing drone control inputs.
     */
    const controlDisplay = document.createElement('div');
    Object.assign(controlDisplay.style, {
      position: 'absolute',
      bottom: '20px',
      left: '20px',
      padding: '15px',
      backgroundColor: 'rgba(5, 10, 14, 0.95)',
      color: '#00F135',
      fontFamily: 'Arial, sans-serif',
      borderRadius: '8px',
      border: '1px solid rgba(0, 241, 53, 0.2)',
      boxShadow: '0 0 20px rgba(0, 241, 53, 0.1)',
      minWidth: '150px',
      zIndex: '1000'
    });
    const controlBars = {
      roll: createControlBar('Roll', 1500, 0, 3000),
      pitch: createControlBar('Pitch', 1500, 0, 3000),
      yaw: createControlBar('Yaw', 1500, 0, 3000),
      throttle: createControlBar('Throttle', 0, 0, 3000)
    };
    controlBarsRef.current = controlBars;
    Object.values(controlBars).forEach(bar => controlDisplay.appendChild(bar.container));
    mountRef.current.appendChild(controlDisplay);

    // Remove compass creation and mounting
    addLog('UI Elements created.');

    /**
     * Handles window resize events to update camera and renderer.
     */
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      updateRendererSize(); // Update FPV renderer size
    };

    window.addEventListener('resize', handleResize);

    const updateFPS = () => {
      const now = performance.now();
      const frameTimes = frameTimesRef.current;
      
      // Add current frame time
      frameTimes.push(now);
      
      // Remove frames older than 1 second
      while (frameTimes[0] <= now - 1000) {
        frameTimes.shift();
      }
      
      // Calculate FPS
      fpsRef.current = frameTimes.length;
      
      // Update global metrics
      window.metrics = {
        fps: fpsRef.current,
        physicsTime: physicsTimeRef.current,
        renderTime: renderTimeRef.current,
        memory: performance.memory?.usedJSHeapSize || 0
      };
    };

    /**
     * Main animation loop for the simulation.
     * @param {number} time - The current timestamp.
     */
    const animate = (time) => {
      const now = performance.now();
      const deltaTime = (now - lastTimeRef.current) / 1000;
      lastTimeRef.current = now;

      // Store the animation frame ID so we can cancel it during cleanup
      window.animationFrameId = requestAnimationFrame(animate);

      // Update controls first
      if (controlsRef.current) {
        controlsRef.current.update();
      }

      // Update FPS calculation
      const frameTimes = frameTimesRef.current;
      frameTimes.push(now);
      while (frameTimes[0] <= now - 1000) {
        frameTimes.shift();
      }
      fpsRef.current = frameTimes.length;

      // Start timing physics
      const physicsStart = performance.now();
      if (physicsEngineRef.current) {
        physicsEngineRef.current.update(deltaTime);
      }
      const physicsEnd = performance.now();
      physicsTimeRef.current = physicsEnd - physicsStart;

      // Update scene animations
      if (sceneRef.current) {
        sceneRef.current.update(deltaTime);
      }

      // Update UFO game if active
      if (simulationRef.current?.ufoGame) {
        simulationRef.current.ufoGame.update();
      }

      // Update camera positions
      if (sceneRef.current?.drone) {
        updateCameraPosition(camera, sceneRef.current.drone);
        if (fpvCameraRef.current) {
          updateFPVCameraPosition(fpvRendererRef.current, sceneRef.current.drone, fpvCameraRef.current);
        }
      }

      // Start timing render
      const renderStart = performance.now();
      renderer.render(sceneRef.current, camera);
      if (fpvRendererRef.current && fpvCameraRef.current) {
        fpvRendererRef.current.render(sceneRef.current, fpvCameraRef.current);
      }
      const renderEnd = performance.now();
      renderTimeRef.current = renderEnd - renderStart;

      // Update metrics
      window.metrics = {
        fps: fpsRef.current,
        physicsTime: physicsTimeRef.current,
        renderTime: renderTimeRef.current,
        memory: performance.memory?.usedJSHeapSize || 0
      };

      // Update UI displays
      if (sceneRef.current?.drone) {
        updatePositionDisplay(positionDisplay, sceneRef.current.drone.position);
        if (controlsRef.current && controlBarsRef.current) {
          updateControlBarsDisplay(controlBarsRef.current, controlsRef.current.getControlInputs());
        }
        updateAxesView(axesView, sceneRef.current.drone.quaternion);
      }
    };

    animate();
    addLog('Animation loop started.');

    // Initialize CustomMapLoader
    const customMapLoader = new CustomMapLoader(scene, physicsEngine);

    // Add loadCustomMap method to simulationRef
    simulationRef.current = {
      resetSimulation,
      loadCustomMap: async (file) => {
        try {
          await customMapLoader.loadCustomMap(file);
          addLog('Custom map loaded successfully');
        } catch (error) {
          addLog(`Error loading custom map: ${error.message}`);
          throw error;
        }
      },
      sceneRef: sceneRef,
      physicsEngine: physicsEngineRef.current,
      controls: controlsRef.current,
      customMapLoader
    };

    // Add position update handler
    positionDisplay.applyButton.addEventListener('click', () => {
      const newPosition = positionDisplay.getInputValues();
      if (sceneRef.current?.drone) {
        const success = sceneRef.current.setDronePosition(
          new THREE.Vector3(newPosition.x, newPosition.y, newPosition.z)
        );
        if (success) {
          // Force sync physics after position update
          const drone = sceneRef.current.drone;
          const physicsBody = drone.userData.physicsBody;
          if (physicsBody && physicsEngineRef.current) {
            physicsEngineRef.current.syncPhysicsWithVisual(drone, physicsBody);
          }
          addLog(`Drone position updated to (${newPosition.x}, ${newPosition.y}, ${newPosition.z})`);
        }
      }
    });

    /**
     * Captures FPV snapshots and sends them for inference when inference is running.
     */
    const startInferencePipeline = () => {
      if (!isInferenceRunning || !selectedModel) return;

      const captureAndInfer = () => {
        if (!fpvRendererRef.current || !fpvCameraRef.current || !sceneRef.current) return;

        // Initialize renderer state if needed
        if (!fpvRendererRef.current._clearColor) {
          fpvRendererRef.current.setClearColor(0x000000, 1);
        }

        // Save current renderer state safely
        let currentRenderTarget = null;
        let currentClearColor = 0x000000;
        let currentClearAlpha = 1;

        try {
          currentRenderTarget = fpvRendererRef.current.getRenderTarget();
          currentClearColor = fpvRendererRef.current._clearColor ? 
            fpvRendererRef.current.getClearColor().getHex() : 0x000000;
          currentClearAlpha = fpvRendererRef.current._clearAlpha || 1;

          // Clear with black to ensure clean render
          fpvRendererRef.current.setClearColor(0x000000, 1);
          fpvRendererRef.current.setRenderTarget(null);
          
          // Render the scene with FPV camera
          fpvRendererRef.current.render(sceneRef.current, fpvCameraRef.current);

          // Wait for next frame to ensure render is complete
          requestAnimationFrame(() => {
            // Capture the snapshot
            const pixelData = captureFPVSnapshot(fpvRendererRef.current);
            
            if (pixelData) {
              const width = fpvRendererRef.current.domElement.width;
              const height = fpvRendererRef.current.domElement.height;
              const dims = [1, 3, height, width];
              
              // Run inference - this transfers ownership of pixelData.buffer
              runInference(pixelData, dims, selectedModel);
            }
          });
        } finally {
          // Restore renderer state only if we successfully saved it
          if (fpvRendererRef.current) {
            fpvRendererRef.current.setClearColor(currentClearColor, currentClearAlpha);
            fpvRendererRef.current.setRenderTarget(currentRenderTarget);
          }
        }
      };

      // Initial capture
      captureAndInfer();

      // Set interval for continuous inference
      const intervalId = setInterval(captureAndInfer, 1000);
      return () => {
        clearInterval(intervalId);
        console.log('Inference pipeline stopped:', selectedModel);
      };
    };

    // Start the inference pipeline when runningInference is active
    let inferenceCleanup = null;
    if (isInferenceRunning) {
      inferenceCleanup = startInferencePipeline();
    }

    // Cleanup only happens on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      
      // Properly dispose of Three.js scene and resources
      if (sceneRef.current) {
        console.log('🧹 Cleaning up DroneScene instance');
        // Cancel any pending animation frames
        if (window.animationFrameId) {
          cancelAnimationFrame(window.animationFrameId);
          window.animationFrameId = null;
        }

        // Dispose of the drone model and its resources
        if (sceneRef.current.drone) {
          sceneRef.current.drone.traverse((child) => {
            if (child.isMesh) {
              child.geometry.dispose();
              if (Array.isArray(child.material)) {
                child.material.forEach(material => material.dispose());
              } else {
                child.material.dispose();
              }
            }
          });
        }

        // Dispose of the environment model and its resources
        if (sceneRef.current.environment) {
          sceneRef.current.environment.traverse((child) => {
            if (child.isMesh) {
              child.geometry.dispose();
              if (Array.isArray(child.material)) {
                child.material.forEach(material => material.dispose());
              } else {
                child.material.dispose();
              }
            }
          });
        }

        // Dispose of any background textures
        if (sceneRef.current.background && sceneRef.current.background.isTexture) {
          sceneRef.current.background.dispose();
        }

        // Clear all objects from the scene
        while(sceneRef.current.children.length > 0) { 
          sceneRef.current.remove(sceneRef.current.children[0]); 
        }

        // Call the dispose method to clean up the singleton instance
        sceneRef.current.dispose();
      }

      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
        mountRef.current.removeChild(positionDisplay.container);
        mountRef.current.removeChild(controlDisplay);
        mountRef.current.removeChild(axesView.axesRenderer.domElement);
        const fpvDisplay = mountRef.current.querySelector('div');
        if (fpvDisplay) {
          mountRef.current.removeChild(fpvDisplay);
        }
      }

      // Dispose of renderers
      if (renderer) {
        renderer.dispose();
      }
      if (fpvRendererRef.current) {
        fpvRendererRef.current.dispose();
      }

      addLog('Cleanup completed on unmount.');
      console.log('Cleaning up Simulation component');
      simulationRef.current = null;
      sceneRef.current = null;
      physicsEngineRef.current = null;
      customMapLoader.removeCurrentMap();
      frameTimesRef.current = [];
      window.metrics = null;
      fpvCameraRef.current = null;
      fpvRendererRef.current = null;
      controlsRef.current = null;
      controlBarsRef.current = null;
      isInitializedRef.current = false;
    };
  }, [addLog, simulationRef]); // Only depend on stable refs

  // Separate effect for inference pipeline
  useEffect(() => {
    if (!isInferenceRunning || !selectedModel || !sceneRef.current) {
      return;
    }

    const cleanup = startInferencePipeline();
    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [isInferenceRunning, selectedModel, startInferencePipeline]);

  /**
   * Updates the main camera position relative to the drone.
   * @param {THREE.PerspectiveCamera} camera - The main camera.
   * @param {THREE.Object3D} drone - The drone object.
   */
  const updateCameraPosition = (camera, drone) => {
    if (drone) {
      const cameraOffset = new THREE.Vector3(0, 1, -3);
      const rotatedOffset = cameraOffset.clone().applyQuaternion(drone.quaternion);
      camera.position.set(
        drone.position.x + rotatedOffset.x,
        drone.position.y + rotatedOffset.y,
        drone.position.z + rotatedOffset.z
      );
      camera.lookAt(drone.position);
    }
  };

  /**
   * Updates the FPV camera position and orientation.
   * @param {THREE.WebGLRenderer} fpvRenderer - The FPV renderer.
   * @param {THREE.Object3D} drone - The drone object.
   */
  const updateFPVCameraPosition = (fpvRenderer, drone, fpvCamera) => {
    if (drone && fpvCamera) {
      fpvCamera.position.copy(drone.position);
      // Create a quaternion for rotating 180 degrees around Y-axis and 20 degrees up around X-axis
      const rotationY180 = new THREE.Quaternion().setFromEuler(new THREE.Euler(-Math.PI * 20/180, Math.PI, 0));
      const combinedRotation = new THREE.Quaternion().multiplyQuaternions(
        drone.quaternion,
        rotationY180
      );
      fpvCamera.setRotationFromQuaternion(combinedRotation);
      const offset = new THREE.Vector3(-.025, 0.2, -0.4).applyQuaternion(combinedRotation);
      fpvCamera.position.add(offset);
    }
  };

  /**
   * Updates the control bars display with current control inputs.
   * @param {Object} controlBars - The control bar elements.
   * @param {Object} channels - The current control input values.
   */
  const updateControlBarsDisplay = (controlBars, channels) => {
    const { roll, pitch, yaw, throttle } = channels;
    updateControlBar(controlBars.roll, roll * 1500 + 1500);
    updateControlBar(controlBars.pitch, pitch * 1500 + 1500);
    updateControlBar(controlBars.yaw, yaw * 1500 + 1500);
    updateControlBar(controlBars.throttle, Math.round(throttle * 3000));
  };

  // ===================NEW===================


  /**
   * Renders the inference control buttons and the YOLOv5 toggle button.
   */
  const handleStartInference = () => {
    if (!isInferenceRunning) {
      setSelectedModel('MobileNetV2');
      addLog('Inference started with model: MobileNetV2');
    }
  };

  const handleStopInference = () => {
    if (isInferenceRunning) {
      addLog('Inference stopped.');
    }
  };

  // ===================NEW===================
  // Toggle function to switch modes
  const toggleMode = () => {
    setIsHorizonMode(prev => !prev);
  };
  // ===================NEW===================

  // Move state update to useEffect
  useEffect(() => {
    if (isHorizonMode && physicsEngineRef.current) {
      physicsEngineRef.current.setHorizonMode(true);
      addLog('Mode switched to Horizon Mode');
    } else if (!isHorizonMode && physicsEngineRef.current) {
      physicsEngineRef.current.setHorizonMode(false);
      addLog('Mode switched to Acro Mode');
    }
  }, [isHorizonMode, addLog]);

  // Add effect to handle gamepad reconnection after inference stops
  useEffect(() => {
    if (!isInferenceRunning && controlsRef.current?.gamepadHandler) {
      console.log('🎮 Inference stopped, checking gamepad connection...');
      controlsRef.current.gamepadHandler.reconnect();
    }
  }, [isInferenceRunning]);

  // Add keyboard event listener for 'R' key
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key.toLowerCase() === 'r') {
        resetSimulation();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Add effect to listen for inference updates
  useEffect(() => {
    let mounted = true;
    
    const handleInferenceUpdate = (event) => {
      if (mounted && event.detail.predictions) {
        console.log('📥 Received inference update:', event.detail.predictions);
        setPredictions(event.detail.predictions);
      }
    };

    window.addEventListener('inferenceUpdate', handleInferenceUpdate);
    
    return () => {
      mounted = false;
      window.removeEventListener('inferenceUpdate', handleInferenceUpdate);
    };
  }, []);

  // Add cleanup for predictions when inference stops
  useEffect(() => {
    if (!isInferenceRunning) {
      setPredictions([]);
    }
  }, [isInferenceRunning]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {/* Three.js container */}
      <div ref={mountRef} style={{ position: 'absolute', width: '100%', height: '100%' }} />
      
      {/* UI Overlay container */}
      <div style={{ position: 'absolute', width: '100%', height: '100%', pointerEvents: 'none' }}>
        {/* Reset button */}
        <button
          onClick={resetSimulation}
          style={{
            position: 'absolute',
            bottom: '200px',
            left: '20px',
            padding: '10px 16px',
            backgroundColor: 'rgba(5, 10, 14, 0.95)',
            color: '#00F135',
            border: '1px solid rgba(0, 241, 53, 0.2)',
            borderRadius: '8px',
            cursor: 'pointer',
            fontFamily: 'monospace',
            fontSize: '13px',
            letterSpacing: '1px',
            boxShadow: '0 0 20px rgba(0, 241, 53, 0.1)',
            zIndex: 1000,
            transition: 'all 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            backdropFilter: 'blur(4px)',
            WebkitBackdropFilter: 'blur(4px)',
            textTransform: 'uppercase',
            pointerEvents: 'auto' // Enable clicking for this element
          }}
          onMouseEnter={(e) => {
            const button = e.currentTarget;
            button.style.backgroundColor = 'rgba(0, 241, 53, 0.15)';
            button.style.transform = 'translateY(-1px)';
            button.style.boxShadow = '0 0 25px rgba(0, 241, 53, 0.2)';
            const icon = button.querySelector('svg');
            if (icon) icon.style.transform = 'rotate(180deg)';
          }}
          onMouseLeave={(e) => {
            const button = e.currentTarget;
            button.style.backgroundColor = 'rgba(5, 10, 14, 0.95)';
            button.style.transform = 'translateY(0)';
            button.style.boxShadow = '0 0 20px rgba(0, 241, 53, 0.1)';
            const icon = button.querySelector('svg');
            if (icon) icon.style.transform = 'rotate(0deg)';
          }}
        >
          <svg
            style={{
              width: '14px',
              height: '14px',
              transition: 'transform 0.3s ease'
            }}
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
          </svg>
          <span>Reset (R)</span>
        </button>
        
        {/* Inference Results */}
        {isInferenceRunning && (
          <InferenceResults 
            predictions={predictions}
            isExpanded={isResultsExpanded}
            onToggleExpand={() => setIsResultsExpanded(prev => !prev)}
          />
        )}
      </div>
    </div>
  );
};

export default Simulation;
