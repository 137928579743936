/**
 * @fileoverview DroneScene class manages a Three.js scene specifically designed for drone simulation.
 * It handles the loading and management of 3D models, physics, lighting, and environment maps.
 * @module DroneScene
 */

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import * as Ammo from 'ammo.js';

/**
 * @class DroneScene
 * @extends THREE.Scene
 * @description A specialized Three.js scene for drone simulation. This class manages:
 * - 3D drone model loading and animations
 * - HDR environment mapping and lighting
 * - Physics integration with Ammo.js
 * - Scene initialization and updates
 * - Drone position and rotation management
 * 
 * @property {THREE.Object3D} drone - The 3D drone model
 * @property {Array<THREE.AnimationClip>} droneAnimations - Array of available drone animations
 * @property {THREE.AnimationMixer} animationMixer - Handles animation playback
 * @property {THREE.Object3D} environment - The 3D environment model
 * @property {Function} addLog - Callback function for logging
 * @property {THREE.Vector3} initialDronePosition - Initial spawn position of the drone
 * @property {THREE.Euler} initialDroneRotation - Initial rotation of the drone
 * @property {THREE.Quaternion} initialDroneQuaternion - Initial quaternion rotation of the drone
 * @property {Ammo.btDiscreteDynamicsWorld} physicsWorld - The Ammo.js physics world instance
 * @property {Ammo.btDiscreteDynamicsWorld} physicsEngine - The Ammo.js physics engine instance
 */
class DroneScene extends THREE.Scene {
  static _instance = null;

  /**
   * @constructor
   * @param {Function} addLog - Function to log messages.
   */
  constructor(addLog) {
    super();
    
    // Ensure only one instance exists
    if (DroneScene._instance) {
      console.warn('⚠️ Attempting to create multiple DroneScene instances. Using existing instance.');
      return DroneScene._instance;
    }
    DroneScene._instance = this;

    this.drone = null;
    this.droneAnimations = [];
    this.animationMixer = null;
    this.environment = null;
    this.addLog = addLog;
    this.addLog('DroneScene constructor: Scene initialized');
    // Define initial state
    this.initialDronePosition = new THREE.Vector3(0, 5, 0);
    this.initialDroneRotation = new THREE.Euler(0, 0, 0);
    this.initialDroneQuaternion = new THREE.Quaternion().setFromEuler(this.initialDroneRotation);
    this.physicsWorld = null;
    this.physicsEngine = null;

    // Group logs for initialization
    console.groupCollapsed('🚁 DroneScene: Constructor');
    console.log('📋 Initial State:', {
      position: this.initialDronePosition.toArray(),
      rotation: this.initialDroneRotation.toArray(),
      quaternion: this.initialDroneQuaternion.toArray()
    });
    console.groupEnd();
  }

  /**
   * @method loadHDREnvironment
   * @private
   * @param {string} [hdrFile='sunshine.hdr'] - The filename of the HDR environment map to load
   * @returns {Promise<void>} A promise that resolves when the HDR environment is loaded
   * @description Loads and sets up an HDR environment map for the scene. This method:
   * - Loads the specified HDR file using RGBELoader
   * - Sets up environment mapping for realistic reflections
   * - Configures the scene background
   * - Handles loading errors with a fallback sky color
   * - Reports loading progress through the logging system
   * 
   * @example
   * // Load default environment
   * await scene.loadHDREnvironment();
   * 
   * // Load specific environment
   * await scene.loadHDREnvironment('custom_environment.hdr');
   */
  async loadHDREnvironment(hdrFile = 'sunshine.hdr') {
    return new Promise((resolve, reject) => {
      const rgbeLoader = new RGBELoader();
      rgbeLoader.setPath('/assets/images/environments/');
      
      // Set default background color in case HDR loading fails
      const defaultSkyColor = new THREE.Color(0x87CEEB);
      this.background = defaultSkyColor;

      rgbeLoader.load(
        hdrFile,
        (texture) => {
          try {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            this.background = texture;
            this.environment = texture;
            this.addLog(`DroneScene loadHDREnvironment: HDR environment ${hdrFile} loaded successfully`);
            resolve();
          } catch (error) {
            console.warn('Error setting up HDR texture:', error);
            // Keep using default sky color if texture setup fails
            this.addLog('DroneScene loadHDREnvironment: Falling back to default sky color');
            resolve();
          }
        },
        (xhr) => {
          this.addLog(`DroneScene loadHDREnvironment: ${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        (error) => {
          console.warn('Error loading HDR environment:', error);
          // Don't reject, just use default sky color
          this.addLog('DroneScene loadHDREnvironment: Using default sky color');
          resolve();
        }
      );
    });
  }

  /**
   * @method changeEnvironmentMap
   * @public
   * @param {string} hdrFile - The filename of the new HDR environment map
   * @returns {Promise<void>} A promise that resolves when the new environment is loaded
   * @description Changes the current environment map to a new HDR file. This method:
   * - Unloads the current environment map
   * - Loads and applies the new environment map
   * - Updates scene lighting and reflections
   * - Handles loading errors gracefully
   * 
   * @example
   * // Change to a new environment
   * await scene.changeEnvironmentMap('night_sky.hdr');
   */
  async changeEnvironmentMap(hdrFile) {
    try {
      await this.loadHDREnvironment(hdrFile);
      this.addLog(`Environment map changed to ${hdrFile}`);
    } catch (error) {
      console.warn('Error changing environment map:', error);
      this.addLog('Failed to change environment map');
    }
  }

  /**
   * @method init
   * @public
   * @async
   * @returns {Promise<void>} A promise that resolves when initialization is complete
   * @description Initializes the complete scene setup. This method:
   * - Sets up scene lighting (ambient and directional lights)
   * - Configures shadow mapping
   * - Loads the HDR environment
   * - Loads and positions the drone model
   * - Loads the environment model
   * - Initializes physics if required
   * - Reports progress through the logging system
   * 
   * @throws {Error} If any initialization step fails
   * 
   * @example
   * const scene = new DroneScene(console.log);
   * await scene.init();
   */
  async init() {
    const startTime = performance.now();
    const logs = {
      steps: [],
      timing: {}
    };

    try {
      console.groupCollapsed('🚁 DroneScene: Initialization');
      
      // Add ambient light
      this.add(new THREE.AmbientLight(0xffffff, 0.5));
      logs.steps.push('Ambient light added');

      // Add directional light with shadows
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(10, 10, 10);
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.width = 2048;
      directionalLight.shadow.mapSize.height = 2048;
      directionalLight.shadow.camera.near = 0.5;
      directionalLight.shadow.camera.far = 500;
      this.add(directionalLight);
      logs.steps.push('Directional light added');

      // Load components
      await this.loadHDREnvironment();
      logs.steps.push('HDR environment loaded');
      
      await this.loadDroneModel();
      logs.steps.push('Drone model loaded');
      
      await this.loadEnvironment();
      logs.steps.push('Environment loaded');

      logs.timing.total = `${(performance.now() - startTime).toFixed(0)}ms`;
      
      console.log('📋 Steps:', logs.steps.join(' → '));
      console.log('⏱️ Time:', logs.timing.total);
      console.log('🔍 State:', {
        hasDrone: !!this.drone,
        dronePosition: this.drone?.position?.toArray(),
        initialPosition: this.initialDronePosition.toArray(),
        initialRotation: this.initialDroneQuaternion.toArray()
      });
      console.groupEnd();
    } catch (error) {
      console.group('❌ DroneScene: Initialization Failed');
      console.error('Error:', error);
      console.trace('Stack trace:');
      console.groupEnd();
      throw error;
    }
  }

  /**
   * @method loadDroneModel
   * @private
   * @returns {Promise<void>} A promise that resolves when the drone model is loaded
   * @description Loads and sets up the drone 3D model. This method:
   * - Uses GLTFLoader with DRACO compression for optimal loading
   * - Sets up materials and textures with correct color spaces
   * - Configures shadow casting and receiving
   * - Initializes animations and animation mixer
   * - Positions the drone at its spawn point
   * - Sets up physics body if physics is enabled
   * 
   * @throws {Error} If model loading or setup fails
   */
  async loadDroneModel() {
    const startTime = performance.now();
    const logs = {
      steps: [],
      timing: {}
    };

    return new Promise((resolve, reject) => {
      console.groupCollapsed('🚁 DroneScene: Loading Drone Model');
      
      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/draco/');
      loader.setDRACOLoader(dracoLoader);

      loader.load(
        '/assets/models/drone/droneforge.glb',
        (gltf) => {
          // Store the loaded 3D model in the class property
          this.drone = gltf.scene;

          // Scale down the model to 0.45% of its original size
          // (GLB files often come in different scales, this makes it match the scene scale)
          this.drone.scale.set(.0045, .0045, .0045);

          // Place the drone at its starting position (defined in constructor as (0, 5, 0))
          this.drone.position.copy(this.initialDronePosition);

          // Set the drone's rotation to its initial orientation
          // (defined in constructor as no rotation: (0, 0, 0))
          this.drone.quaternion.copy(this.initialDroneQuaternion);

          // Traverse the drone model to ensure materials are properly applied
          this.drone.traverse((child) => {
            if (child.isMesh) {
              // Ensure the material is using its map (texture)
              if (child.material.map) {
                child.material.map.colorSpace = THREE.SRGBColorSpace;
                child.material.map.flipY = false; // GLB files typically don't need texture flipping
                child.material.needsUpdate = true;
              }
              
              // Apply these settings to other texture types if present
              ['normalMap', 'roughnessMap', 'metalnessMap'].forEach(mapType => {
                if (child.material[mapType]) {
                  child.material[mapType].colorSpace = THREE.LinearSRGBColorSpace;
                  child.material[mapType].flipY = false;
                }
              });

              // Enable shadows for each mesh
              child.castShadow = true;
              child.receiveShadow = true;
            }
          });

          this.add(this.drone);

          // Set up animations
          this.animationMixer = new THREE.AnimationMixer(this.drone);
          this.droneAnimations = gltf.animations;
          
          // Play all animations
          this.droneAnimations.forEach((clip) => {
            const action = this.animationMixer.clipAction(clip);
            action.play();
          });

          logs.steps.push('Model loaded');
          logs.steps.push('Materials configured');
          logs.steps.push('Animations initialized');
          
          logs.timing.total = `${(performance.now() - startTime).toFixed(0)}ms`;
          console.log('📋 Steps:', logs.steps.join(' → '));
          console.log('⏱️ Time:', logs.timing.total);
          console.groupEnd();
          resolve();
        },
        (xhr) => {
          const progress = (xhr.loaded / xhr.total * 100).toFixed(0);
          logs.steps.push(`Loading: ${progress}%`);
        },
        (error) => {
          console.group('❌ DroneScene: Model Loading Failed');
          console.error('Error:', error);
          console.groupEnd();
          reject(error);
        }
      );
    });
  }

  /**
   * @method loadEnvironment
   * @private
   * @returns {Promise<void>} A promise that resolves when the environment is loaded
   * @description Loads the 3D environment model. This method:
   * - Uses GLTFLoader with DRACO compression for optimal loading
   * - Removes any existing environment
   * - Adds the new environment to the scene
   * - Reports loading progress
   * 
   * @throws {Error} If environment loading fails
   */
  loadEnvironment() {
    return new Promise((resolve, reject) => {
      console.log('Starting environment load...');
      const loader = new GLTFLoader();

      // Use DRACOLoader for compressed meshes
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/draco/');
      loader.setDRACOLoader(dracoLoader);

      // Load the default environment model
      loader.load(
        '/assets/models/gltf_enviorment3/Map_v1.gltf',
        (gltf) => {
          console.log('Environment model loaded successfully');
          if (this.environment) {
            this.remove(this.environment);
          }
          this.environment = gltf.scene;
          this.add(this.environment);
          this.addLog('DroneScene loadEnvironment: Environment loaded successfully');
          resolve();
        },
        (xhr) => {
          console.log(`Loading progress: ${(xhr.loaded / xhr.total) * 100}% loaded`);
          this.addLog(`DroneScene loadEnvironment: ${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        (error) => {
          console.error('Error loading environment:', error);
          this.addLog(`DroneScene loadEnvironment: An error occurred - ${error.message}`);
          reject(error);
        }
      );
    });
  }

  /**
   * @method update
   * @public
   * @param {number} deltaTime - Time elapsed since the last frame in seconds
   * @description Updates the scene state for each frame. This method:
   * - Updates animation states
   * - Updates physics simulation if enabled
   * - Updates any dynamic elements in the scene
   * 
   * @example
   * // In animation loop
   * scene.update(clock.getDelta());
   */
  update(deltaTime) {
    // Update animations
    if (this.animationMixer) {
      this.animationMixer.update(deltaTime);
    }
  }

  /**
   * @method resetDroneToSpawn
   * @public
   * @description Resets the drone to its initial state. This method:
   * - Resets position to spawn point
   * - Resets rotation to initial values
   * - Resets physics body state if physics is enabled
   * - Clears all velocities and forces
   * - Ensures proper synchronization between visual and physics representations
   * 
   * @example
   * // Reset drone after crash
   * scene.resetDroneToSpawn();
   */
  resetDroneToSpawn() {
    console.groupCollapsed('🚁 DroneScene: Reset Position');
    
    if (!this.drone) {
      console.warn('⚠️ Cannot reset: drone not loaded');
      console.groupEnd();
      return false;
    }

    const logs = {
      initial: this.initialDronePosition.toArray(),
      final: null,
      physics: !!this.physicsEngine
    };

    console.log('Resetting drone to spawn position:', this.initialDronePosition.toArray());
    
    // Update visual representation first
    this.drone.position.copy(this.initialDronePosition);
    this.drone.quaternion.copy(this.initialDroneQuaternion);

    const physicsBody = this.drone.userData.physicsBody;
    if (physicsBody && this.physicsEngine) {
      // First, clear all forces and velocities
      this.physicsEngine.clearDroneForces();

      // Create transform and vectors
      const transform = new Ammo.btTransform();
      const position = new Ammo.btVector3(
        this.initialDronePosition.x,
        this.initialDronePosition.y,
        this.initialDronePosition.z
      );
      const rotation = new Ammo.btQuaternion(
        this.initialDroneQuaternion.x,
        this.initialDroneQuaternion.y,
        this.initialDroneQuaternion.z,
        this.initialDroneQuaternion.w
      );

      try {
        // Set transform
        transform.setIdentity();
        transform.setOrigin(position);
        transform.setRotation(rotation);

        // Update motion state
        const motionState = physicsBody.getMotionState();
        if (motionState) {
          motionState.setWorldTransform(transform);
        }

        // Update physics body state
        physicsBody.setWorldTransform(transform);
        physicsBody.setCenterOfMassTransform(transform);
        physicsBody.activate(true);

        // Force sync with physics engine
        if (this.physicsEngine) {
          this.physicsEngine.syncPhysicsWithVisual(this.drone, physicsBody);
        }

        // Verify physics update
        const verifyTransform = physicsBody.getWorldTransform();
        const verifyPos = verifyTransform.getOrigin();
        console.log('Physics body position after reset:', {
          x: verifyPos.x(),
          y: verifyPos.y(),
          z: verifyPos.z()
        });
      } finally {
        // Cleanup Ammo.js objects
        Ammo.destroy(transform);
        Ammo.destroy(position);
        Ammo.destroy(rotation);
      }
    }

    console.log('Final drone state:', {
      position: this.drone.position.toArray(),
      quaternion: this.drone.quaternion.toArray(),
      hasPhysicsEngine: !!this.physicsEngine
    });

    logs.final = this.drone.position.toArray();
    console.log('📋 Reset Complete:', {
      position: logs.final,
      quaternion: this.drone.quaternion.toArray(),
      hasPhysicsEngine: logs.physics
    });
    console.groupEnd();
    return true;
  }

  /**
   * @method setDronePosition
   * @public
   * @param {THREE.Vector3} newPosition - The new position vector
   * @returns {boolean} True if position was set successfully, false if drone doesn't exist
   * @description Sets the drone's position in both visual and physics worlds. This method:
   * - Updates the Three.js model position
   * - Updates the physics body position if physics is enabled
   * - Resets velocities and forces
   * - Ensures proper synchronization between visual and physics representations
   * - Verifies the update was successful
   * 
   * @example
   * // Set drone to specific coordinates
   * const newPos = new THREE.Vector3(10, 20, 30);
   * const success = scene.setDronePosition(newPos);
   * 
   * @throws {Error} If physics body update fails
   */
  setDronePosition(newPosition) {
    console.groupCollapsed('🚁 DroneScene: Set Position');
    
    if (!this.drone || !newPosition.isVector3) {
      console.warn('⚠️ Invalid position update request');
      console.groupEnd();
      return false;
    }

    const logs = {
      previous: this.drone.position.toArray(),
      target: newPosition.toArray(),
      final: null
    };

    console.log('Setting drone position:', {
      newPosition: newPosition.toArray(),
      currentPosition: this.drone.position.toArray(),
      hasPhysicsEngine: !!this.physicsEngine
    });

    // Update initial position reference and visual representation first
    this.initialDronePosition.copy(newPosition);
    this.drone.position.copy(newPosition);

    const physicsBody = this.drone.userData.physicsBody;
    if (physicsBody && this.physicsEngine) {
      // First, clear all forces and velocities
      this.physicsEngine.clearDroneForces();

      // Create transform and vectors
      const transform = new Ammo.btTransform();
      const position = new Ammo.btVector3(newPosition.x, newPosition.y, newPosition.z);
      const rotation = new Ammo.btQuaternion(
        this.initialDroneQuaternion.x,
        this.initialDroneQuaternion.y,
        this.initialDroneQuaternion.z,
        this.initialDroneQuaternion.w
      );

      try {
        // Set transform
        transform.setIdentity();
        transform.setOrigin(position);
        transform.setRotation(rotation);

        // Update motion state
        const motionState = physicsBody.getMotionState();
        if (motionState) {
          motionState.setWorldTransform(transform);
        }

        // Update physics body state
        physicsBody.setWorldTransform(transform);
        physicsBody.setCenterOfMassTransform(transform);
        physicsBody.activate(true);

        // Force sync with physics engine
        this.physicsEngine.syncPhysicsWithVisual(this.drone, physicsBody);

        // Verify physics update
        const verifyTransform = physicsBody.getWorldTransform();
        const verifyPos = verifyTransform.getOrigin();
        console.log('Physics body position after update:', {
          x: verifyPos.x(),
          y: verifyPos.y(),
          z: verifyPos.z()
        });
      } finally {
        // Cleanup Ammo.js objects
        Ammo.destroy(transform);
        Ammo.destroy(position);
        Ammo.destroy(rotation);
      }
    }

    console.log('Final drone state:', {
      position: this.drone.position.toArray(),
      quaternion: this.drone.quaternion.toArray(),
      hasPhysicsEngine: !!this.physicsEngine
    });

    logs.final = this.drone.position.toArray();
    console.log('📋 Position Updated:', {
      from: logs.previous,
      to: logs.final,
      hasPhysicsEngine: !!this.physicsEngine
    });
    console.groupEnd();
    return true;
  }

  /**
   * @method setPhysicsEngine
   * @public
   * @param {Ammo.btDiscreteDynamicsWorld} engine - The physics engine instance
   * @description Sets the physics engine reference for the scene. This method:
   * - Stores the physics engine instance
   * - Logs the reference status
   * 
   * @example
   * // Set physics engine
   * scene.setPhysicsEngine(physicsEngine);
   */
  setPhysicsEngine(engine) {
    this.physicsEngine = engine;
    console.log('Physics engine reference set:', !!this.physicsEngine);
  }

  /**
   * Properly dispose of the scene and its resources
   */
  dispose() {
    console.log('🧹 Disposing DroneScene instance');
    
    // Clear the singleton instance
    DroneScene._instance = null;
    
    // Additional cleanup can be added here if needed
  }
}

export default DroneScene;
