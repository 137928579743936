/**
 * @fileoverview CustomMapLoader - A utility class for loading and processing 3D models in various formats
 * for use in a drone simulation environment. Handles GLB, GLTF, PLY formats and manages physics integration.
 * 
 * @requires three
 * @requires three/examples/jsm/loaders/GLTFLoader
 * @requires three/examples/jsm/loaders/DRACOLoader
 * @requires three/examples/jsm/loaders/PLYLoader
 * @requires three/examples/jsm/utils/BufferGeometryUtils
 */

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { PointsMaterial, Points, BufferGeometry, Float32BufferAttribute } from 'three';
import React from 'react';
import { createRoot } from 'react-dom/client';
import LoadingMapScreen from '../components/LoadingMapScreen';
import { initWasm, GeometryProcessor } from './wasmGeometryProcessor.js';

/**
 * @class CustomMapLoader
 * @description Handles loading and processing of custom 3D environment maps for the drone simulation.
 * Supports GLB, GLTF, and PLY formats with associated textures and binary files.
 * Manages physics body creation and material setup for loaded maps.
 * 
 * @property {THREE.Scene} scene - The Three.js scene to add the map to
 * @property {PhysicsEngine} physicsEngine - The Ammo.js physics engine instance
 * @property {THREE.Object3D} currentCustomMap - Currently loaded map object
 * @property {THREE.Vector3} defaultMapScale - Default scale for loaded maps
 * @property {THREE.LoadingManager} loadingManager - Manages loading of all assets
 * @property {GLTFLoader} loader - Loader for GLTF/GLB files
 * @property {PLYLoader} plyLoader - Loader for PLY files
 * @property {boolean} debugPhysics - Flag to show physics debug visualization
 * @property {THREE.Points} physicsPoints - Visual representation of physics bodies
 */

/**
 * Mathematical Concepts Used:
 * 
 * 1. Matrix Transformations:
 *    - World Matrix (matrixWorld): Represents cumulative transformations
 *    - Vertex Transformation: v' = M × v (where M is the transformation matrix)
 * 
 * 2. Volume Calculation:
 *    - Uses signed tetrahedron volumes: V = (a · (b × c)) / 6
 *    - Aggregates volumes for complete mesh volume
 * 
 * 3. Bounding Box Computation:
 *    - Finds min/max points of geometry for scaling
 *    - Used in autoScaleMap for proportional scaling
 * 
 * 4. Physics Body Creation:
 *    - Triangle mesh creation for collision detection
 *    - Vertex transformation for physics body alignment
 */

class CustomMapLoader {
  /**
   * @constructor
   * @param {THREE.Scene} scene - The Three.js scene to add the map to
   * @param {PhysicsEngine} physicsEngine - The Ammo.js physics engine instance
   */
  constructor(scene, physicsEngine) {
    this.scene = scene;
    this.physicsEngine = physicsEngine;
    this.currentCustomMap = null;
    this.defaultMapScale = new THREE.Vector3(1, 1, 1);

    // Create a custom loading manager
    this.loadingManager = new THREE.LoadingManager();
    
    // Initialize the GLTF loader with our custom manager
    this.loader = new GLTFLoader(this.loadingManager);
    
    // Setup DRACO loader for compressed models
    const dracoLoader = new DRACOLoader(this.loadingManager);
    dracoLoader.setDecoderPath('/draco/');
    this.loader.setDRACOLoader(dracoLoader);

    // Add PLY loader
    this.plyLoader = new PLYLoader(this.loadingManager);

    // Change default to false and add a setter
    this.debugPhysics = false;
    this.physicsPoints = null;

    this.loadingProgress = 0;
    this.currentTask = '';
    this.loadingElement = null;
    this.loadingRoot = null; // Add this to store the React root

    // Initialize WASM
    this.wasmInitialized = false;
    this.wasmInitPromise = initWasm().then(() => {
      this.wasmInitialized = true;
    });
  }

  async ensureWasmInitialized() {
    if (!this.wasmInitialized) {
      await this.wasmInitPromise;
    }
  }

  updateLoadingProgress(progress, task) {
    this.loadingProgress = progress;
    this.currentTask = task;
    
    if (this.loadingRoot) {
      this.loadingRoot.render(
        <LoadingMapScreen progress={progress} currentTask={task} />
      );
    }
  }

  showLoadingScreen() {
    this.loadingElement = document.createElement('div');
    document.body.appendChild(this.loadingElement);
    this.loadingRoot = createRoot(this.loadingElement);
    this.updateLoadingProgress(0, 'Initializing...');
  }

  hideLoadingScreen() {
    if (this.loadingRoot) {
      this.loadingRoot.unmount();
      this.loadingRoot = null;
    }
    if (this.loadingElement) {
      document.body.removeChild(this.loadingElement);
      this.loadingElement = null;
    }
  }

  /**
   * @method loadCustomMap
   * @async
   * @param {Object} mapData - Object containing map files
   * @param {File} [mapData.glb] - GLB file if using single-file format
   * @param {File} [mapData.gltf] - GLTF file if using multi-file format
   * @param {File} [mapData.bin] - Binary file for GLTF
   * @param {File[]} [mapData.textures] - Array of texture files for GLTF
   * @param {File} [mapData.ply] - PLY file for point cloud or mesh format
   * @returns {Promise<boolean>} - Resolves to true when map is loaded successfully
   * @throws {Error} If loading or processing fails
   * 
   * @description 
   * Loads a custom map from provided files. The method handles three main formats:
   * 1. PLY (point cloud or mesh)
   * 2. GLB (single file)
   * 3. GLTF (with associated bin and texture files)
   * 
   * The loading process includes:
   * - File validation and loading
   * - Geometry processing and scaling
   * - Physics body creation
   * - Material setup and optimization
   */
  async loadCustomMap(mapData) {
    try {
      console.groupCollapsed('🗺️ Map Loading');
      const startTime = performance.now();
      
      // Check drone mass before loading map
      if (this.physicsEngine) {
        const massInfo = this.physicsEngine.getDroneMass();
        console.log('📊 Initial Drone State:', {
          status: massInfo.status,
          mass: massInfo.total,
          details: massInfo
        });
      }

      this.showLoadingScreen();
      this.updateLoadingProgress(10, 'Cleaning up previous map...');
      this.removeCurrentMap();
      
      if (this.scene.environment) {
        this.scene.remove(this.scene.environment);
        this.scene.environment = null;
      }

      let gltf;

      if (mapData.ply) {
        this.updateLoadingProgress(20, 'Loading PLY file...');
        const plyUrl = URL.createObjectURL(mapData.ply);
        const geometry = await this.loadPLY(plyUrl);
        URL.revokeObjectURL(plyUrl);

        if (geometry.index === null || geometry.index.count === 0) {
          console.log('📍 Processing point cloud data');
          const material = new THREE.PointsMaterial({
            size: 0.05,
            vertexColors: geometry.attributes.color !== undefined,
            sizeAttenuation: true
          });

          const points = new THREE.Points(geometry, material);
          this.currentCustomMap = new THREE.Group();
          this.currentCustomMap.add(points);

          geometry.computeBoundingBox();
          const bbox = geometry.boundingBox;
          const size = new THREE.Vector3();
          bbox.getSize(size);

          const maxDim = Math.max(size.x, size.y, size.z);
          const scale = 50 / maxDim;
          points.scale.set(scale, scale, scale);

          const center = new THREE.Vector3();
          bbox.getCenter(center);
          points.position.set(
            -center.x * scale,
            -bbox.min.y * scale,
            -center.z * scale
          );

        } else {
          console.log('🔷 Processing mesh data');
          const material = new THREE.MeshStandardMaterial({
            vertexColors: geometry.attributes.color !== undefined,
            side: THREE.DoubleSide,
            roughness: 0.7,
            metalness: 0.0
          });

          const mesh = new THREE.Mesh(geometry, material);
          this.currentCustomMap = new THREE.Group();
          this.currentCustomMap.add(mesh);

          geometry.computeBoundingBox();
          const bbox = geometry.boundingBox;
          const size = new THREE.Vector3();
          bbox.getSize(size);

          const maxDim = Math.max(size.x, size.y, size.z);
          const scale = 50 / maxDim;
          mesh.scale.set(scale, scale, scale);

          const center = new THREE.Vector3();
          bbox.getCenter(center);
          mesh.position.set(
            -center.x * scale,
            -bbox.min.y * scale,
            -center.z * scale
          );

          if (this.physicsEngine) {
            await this.createDetailedPhysicsBody(mesh);
          }
        }

        this.scene.add(this.currentCustomMap);
        
      } else if (mapData.glb) {
        this.updateLoadingProgress(20, 'Loading GLB file...');
        const glbUrl = URL.createObjectURL(mapData.glb);
        gltf = await this.loadGLTF(glbUrl);
        URL.revokeObjectURL(glbUrl);

        this.updateLoadingProgress(40, 'Processing geometry...');
        this.currentCustomMap = gltf.scene;
        
        this.updateLoadingProgress(60, 'Scaling map...');
        await this.autoScaleMap(this.currentCustomMap);
        
        this.updateLoadingProgress(80, 'Creating physics bodies...');
        await this.processLoadedMap(this.currentCustomMap);
        
        this.updateLoadingProgress(90, 'Adding to scene...');
        this.scene.add(this.currentCustomMap);

      } else {
        this.updateLoadingProgress(20, 'Loading GLTF files...');
        const gltfUrl = URL.createObjectURL(mapData.gltf);
        const binUrl = URL.createObjectURL(mapData.bin);
        const textureMap = new Map(
          mapData.textures.map(file => [file.name, URL.createObjectURL(file)])
        );

        const originalURLModifier = this.loadingManager.resolveURL;
        this.loadingManager.resolveURL = (url) => {
          const fileName = url.split('/').pop();
          if (fileName === mapData.bin.name) return binUrl;
          const textureURL = textureMap.get(fileName);
          if (textureURL) return textureURL;
          return originalURLModifier ? originalURLModifier(url) : url;
        };

        gltf = await this.loadGLTF(gltfUrl);
        
        this.currentCustomMap = gltf.scene;
        await this.autoScaleMap(this.currentCustomMap);
        await this.processLoadedMap(this.currentCustomMap);
        this.scene.add(this.currentCustomMap);
        
        URL.revokeObjectURL(gltfUrl);
        URL.revokeObjectURL(binUrl);
        textureMap.forEach((url) => URL.revokeObjectURL(url));
        this.loadingManager.resolveURL = originalURLModifier;
      }
      
      this.updateLoadingProgress(95, 'Setting up drone physics...');
      // Setup drone physics after map is fully loaded
      if (this.physicsEngine) {
        try {
          await this.physicsEngine.setupDroneAfterMap();
          console.log('✅ Drone physics initialized');
          
          // Check final drone mass
          const finalMassInfo = this.physicsEngine.getDroneMass();
          console.log('📊 Final Drone State:', {
            status: finalMassInfo.status,
            mass: finalMassInfo.total,
            details: finalMassInfo
          });

          if (finalMassInfo.childBodies.length > 0) {
            console.warn('⚠️ Warning: Additional physics bodies detected on drone after map load!');
          }
        } catch (error) {
          console.warn('⚠️ Failed to setup drone physics:', error);
        }
      }
      
      if (this.scene.resetDroneToSpawn) {
        this.scene.resetDroneToSpawn();
      }
      
      console.log('✅ Map loaded successfully:', {
        type: mapData.ply ? 'PLY' : mapData.glb ? 'GLB' : 'GLTF',
        processingTime: `${Math.round(performance.now() - startTime)}ms`
      });
      
      this.updateLoadingProgress(100, 'Complete!');
      setTimeout(() => this.hideLoadingScreen(), 500);
      
      return true;
    } catch (error) {
      console.error("❌ Map loading failed:", error);
      this.hideLoadingScreen();
      throw error;
    } finally {
      console.groupEnd();
    }
  }

  /**
   * @method autoScaleMap
   * @private
   * @param {THREE.Object3D} mapObject - The loaded map object to scale
   * 
   * @description
   * Automatically scales the map to fit within target dimensions while maintaining proportions.
   * Uses bounding box calculations to determine appropriate scaling factors.
   * 
   * Mathematical Process:
   * 1. Calculate bounding box dimensions
   * 2. Compute scale factors: targetSize / currentSize for each axis
   * 3. Use minimum scale factor to maintain proportions
   * 4. Apply uniform scaling to object
   * 5. Center and ground the object based on new bounds
   */
  async autoScaleMap(mapObject) {
    try {
      console.groupCollapsed('🗺️ Map Scaling');
      const startTime = performance.now();

      // Collect all vertices from the mapObject
      const verticesList = [];
      let meshCount = 0;
      let totalVertices = 0;

      mapObject.traverse((child) => {
        if (child.isMesh && child.geometry) {
          meshCount++;
          const positionAttr = child.geometry.attributes.position;
          if (positionAttr) {
            totalVertices += positionAttr.count;
            const vertices = new Float32Array(positionAttr.count * 3);
            const vertex = new THREE.Vector3();
            
            for (let i = 0; i < positionAttr.count; i++) {
              vertex.fromBufferAttribute(positionAttr, i);
              vertex.applyMatrix4(child.matrixWorld);
              vertices[i * 3] = vertex.x;
              vertices[i * 3 + 1] = vertex.y;
              vertices[i * 3 + 2] = vertex.z;
            }
            verticesList.push(vertices);
          }
        }
      });

      // Combine all vertices
      const allVertices = new Float32Array(totalVertices * 3);
      let offset = 0;
      for (const vertices of verticesList) {
        allVertices.set(vertices, offset);
        offset += vertices.length;
      }

      if (allVertices.length === 0) {
        throw new Error("No vertices found in the map object");
      }

      const geometryProcessor = new GeometryProcessor(allVertices);
      
      try {
        const bounds = geometryProcessor.calculate_bounds();
        const min = Array.from(bounds.min);
        const max = Array.from(bounds.max);

        // Calculate current size
        const currentSize = [
          max[0] - min[0],
          max[1] - min[1],
          max[2] - min[2],
        ];

        // Desired target dimensions
        const targetSize = { width: 100, height: 30, depth: 100 };

        // Compute scale factors
        const scaleX = targetSize.width / currentSize[0];
        const scaleY = targetSize.height / currentSize[1];
        const scaleZ = targetSize.depth / currentSize[2];

        // Use the smallest scale factor to maintain proportions
        const scale = Math.min(scaleX, scaleY, scaleZ);

        // Reset and apply new transform
        mapObject.matrix.identity();
        mapObject.position.set(0, 0, 0);
        mapObject.rotation.set(0, 0, 0);
        mapObject.scale.set(1, 1, 1);
        mapObject.scale.set(scale, scale, scale);

        // Center and ground the object
        const center = [
          (min[0] + max[0]) / 2,
          (min[1] + max[1]) / 2,
          (min[2] + max[2]) / 2,
        ];
        
        mapObject.position.set(
          -center[0] * scale,
          -min[1] * scale,
          -center[2] * scale
        );

        mapObject.updateMatrix();
        this.currentMapScale = scale;

        const processingTime = Math.round(performance.now() - startTime);
        console.log('📊 Map Statistics:', {
          meshes: meshCount,
          vertices: totalVertices,
          processingTime: `${processingTime}ms`,
          bounds: { min, max },
          scale,
          dimensions: currentSize
        });
      } finally {
        geometryProcessor.dispose();
      }
    } catch (error) {
      console.error("❌ Map scaling failed:", error);
      throw error;
    } finally {
      console.groupEnd();
    }
  }

  /**
   * @method processLoadedMap
   * @private
   * @async
   * @param {THREE.Object3D} mapObject - The loaded map object to process
   */
  async processLoadedMap(mapObject) {
    console.groupCollapsed('🔨 Map Processing');
    const startTime = performance.now();
    let meshCount = 0;
    let totalVertices = 0;
    let totalTriangles = 0;

    try {
      mapObject.updateMatrixWorld(true);

      const meshes = [];
      mapObject.traverse((child) => {
        // Skip if this is the drone or a child of the drone
        if (child.isMesh && 
            child !== this.scene.drone && 
            !this.scene.drone?.children.includes(child) &&
            !child.isDescendantOf?.(this.scene.drone)) {
          
          meshes.push(child);
          child.castShadow = true;
          child.receiveShadow = true;

          if (child.material) {
            this.setupMaterial(child.material);
          }
        }
      });

      // Process physics bodies with delay between each to allow GC
      if (this.physicsEngine) {
        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
        
        for (const mesh of meshes) {
          if (mesh.geometry) {
            try {
              const stats = await this.createDetailedPhysicsBody(mesh);
              if (stats) {
                meshCount++;
                totalVertices += stats.vertices;
                totalTriangles += stats.triangles;
              }
              // Add small delay between processing each mesh
              await delay(10);
            } catch (error) {
              console.warn(`Failed to process mesh ${mesh.name}:`, error);
              continue; // Continue with next mesh even if one fails
            }
          }
        }

        // After map physics are set up, initialize drone physics
        try {
          await this.physicsEngine.setupDroneAfterMap();
          console.log('✅ Drone physics initialized after map setup');
        } catch (error) {
          console.warn('⚠️ Failed to setup drone physics:', error);
        }
      }

      const processingTime = Math.round(performance.now() - startTime);
      console.log('📊 Processing Complete:', {
        meshes: meshCount,
        totalVertices,
        totalTriangles,
        processingTime: `${processingTime}ms`
      });
    } catch (error) {
      console.error("❌ Map processing failed:", error);
      throw error;
    } finally {
      console.groupEnd();
    }
  }

  /**
   * @method createDetailedPhysicsBody
   * @private
   * @param {THREE.Mesh} mesh - The mesh to create a physics body for
   * 
   * @description
   * Creates a detailed physics body for collision detection using triangle mesh.
   * 
   * Process:
   * 1. Ensures geometry is indexed
   * 2. Creates triangle mesh for physics
   * 3. Transforms vertices using world matrix
   * 4. Creates visualization points for debugging
   * 5. Sets up physics properties (friction, restitution, etc.)
   * 
   * Physics Properties:
   * - Friction: 0.8 (high friction for stability)
   * - Rolling Friction: 0.1 (moderate rolling resistance)
   * - Restitution: 0.2 (slight bounce)
   * - Damping: 0.1 (moderate movement damping)
   */
  async createDetailedPhysicsBody(mesh) {
    if (!this.physicsEngine) return null;

    // Skip if this is the drone mesh
    if (mesh === this.scene.drone) {
      console.log('⏩ Skipping physics creation for drone mesh');
      return null;
    }

    // Track Ammo objects for cleanup
    const ammoObjects = [];

    try {
      const startTime = performance.now();
      const geometry = mesh.geometry;
      let indexedGeometry = geometry;
      if (!geometry.index) {
        indexedGeometry = mergeVertices(geometry);
      }

      // Get vertices and indices
      const vertices = indexedGeometry.attributes.position.array;
      const indices = indexedGeometry.index.array;

      // Create triangle mesh with smaller chunks to avoid memory spikes
      const triangleMesh = new this.physicsEngine.Ammo.btTriangleMesh(true, true);
      ammoObjects.push(triangleMesh);
      
      // Transform setup
      const worldMatrix = new THREE.Matrix4();
      mesh.updateMatrixWorld(true);
      worldMatrix.copy(mesh.matrixWorld);

      // Process vertices in chunks
      const chunkSize = 1000; // Process 1000 triangles at a time
      const tempVec = new THREE.Vector3();
      const vertex0 = new this.physicsEngine.Ammo.btVector3(0, 0, 0);
      const vertex1 = new this.physicsEngine.Ammo.btVector3(0, 0, 0);
      const vertex2 = new this.physicsEngine.Ammo.btVector3(0, 0, 0);
      ammoObjects.push(vertex0, vertex1, vertex2);

      for (let i = 0; i < indices.length; i += chunkSize * 3) {
        const endIdx = Math.min(i + chunkSize * 3, indices.length);
        
        for (let j = i; j < endIdx; j += 3) {
          const idx0 = indices[j] * 3;
          const idx1 = indices[j + 1] * 3;
          const idx2 = indices[j + 2] * 3;

          for (const [idx, vertex] of [[idx0, vertex0], [idx1, vertex1], [idx2, vertex2]]) {
            tempVec.set(
              vertices[idx],
              vertices[idx + 1],
              vertices[idx + 2]
            );
            tempVec.applyMatrix4(worldMatrix);
            vertex.setValue(tempVec.x, tempVec.y, tempVec.z);
          }

          triangleMesh.addTriangle(vertex0, vertex1, vertex2, true);
        }

        // Allow GC to run between chunks
        await new Promise(resolve => setTimeout(resolve, 0));
      }

      // Create physics body with optimized settings
      const shape = new this.physicsEngine.Ammo.btBvhTriangleMeshShape(
        triangleMesh,
        true,
        true
      );
      ammoObjects.push(shape);

      const transform = new this.physicsEngine.Ammo.btTransform();
      ammoObjects.push(transform);
      transform.setIdentity();

      const motionState = new this.physicsEngine.Ammo.btDefaultMotionState(transform);
      ammoObjects.push(motionState);
      
      const mass = 0; // Static body
      const localInertia = new this.physicsEngine.Ammo.btVector3(0, 0, 0);
      ammoObjects.push(localInertia);

      const rbInfo = new this.physicsEngine.Ammo.btRigidBodyConstructionInfo(
        mass,
        motionState,
        shape,
        localInertia
      );
      ammoObjects.push(rbInfo);

      const body = new this.physicsEngine.Ammo.btRigidBody(rbInfo);
      ammoObjects.push(body);
      
      body.setFriction(0.8);
      body.setRollingFriction(0.1);
      body.setRestitution(0.2);
      body.setDamping(0.1, 0.1);
      body.setActivationState(4);

      this.physicsEngine.physicsWorld.addRigidBody(body);
      mesh.userData.physicsBody = body;
      mesh.userData.ammoObjects = ammoObjects;

      return {
        vertices: vertices.length / 3,
        triangles: indices.length / 3,
        processingTime: Math.round(performance.now() - startTime)
      };

    } catch (error) {
      console.warn(`⚠️ Physics body creation failed for mesh:`, {
        name: mesh.name || 'unnamed',
        error: error.message
      });
      
      // Clean up any created Ammo objects on error
      ammoObjects.forEach(obj => {
        try {
          this.physicsEngine.Ammo.destroy(obj);
        } catch (e) {
          console.warn('Failed to destroy Ammo object:', e);
        }
      });
      
      return null;
    }
  }

  /**
   * @method calculateMeshVolume
   * @private
   * @param {THREE.Mesh} mesh - The mesh to calculate volume for
   * @returns {number} The approximate volume of the mesh
   * 
   * @description
   * Calculates the volume of a mesh by summing the signed volumes of tetrahedra
   * formed by triangles and the origin.
   * 
   * Mathematical Concept:
   * For each triangle with vertices v1, v2, v3:
   * Volume = Σ (v1 · (v2 × v3)) / 6
   * Where · is dot product and × is cross product
   */
  calculateMeshVolume(mesh) {
    const geometry = mesh.geometry;
    const vertices = geometry.attributes.position.array;
    const indices = geometry.index ? geometry.index.array : null;
    let volume = 0;

    if (indices) {
      // For indexed geometry
      for (let i = 0; i < indices.length; i += 3) {
        const v1 = new THREE.Vector3(
          vertices[indices[i] * 3],
          vertices[indices[i] * 3 + 1],
          vertices[indices[i] * 3 + 2]
        );
        const v2 = new THREE.Vector3(
          vertices[indices[i + 1] * 3],
          vertices[indices[i + 1] * 3 + 1],
          vertices[indices[i + 1] * 3 + 2]
        );
        const v3 = new THREE.Vector3(
          vertices[indices[i + 2] * 3],
          vertices[indices[i + 2] * 3 + 1],
          vertices[indices[i + 2] * 3 + 2]
        );
        volume += this.signedVolumeOfTriangle(v1, v2, v3);
      }
    } else {
      // For non-indexed geometry
      for (let i = 0; i < vertices.length; i += 9) {
        const v1 = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
        const v2 = new THREE.Vector3(vertices[i + 3], vertices[i + 4], vertices[i + 5]);
        const v3 = new THREE.Vector3(vertices[i + 6], vertices[i + 7], vertices[i + 8]);
        volume += this.signedVolumeOfTriangle(v1, v2, v3);
      }
    }

    return Math.abs(volume);
  }

  /**
   * @method signedVolumeOfTriangle
   * @private
   * @param {THREE.Vector3} p1 - First vertex of the triangle
   * @param {THREE.Vector3} p2 - Second vertex of the triangle
   * @param {THREE.Vector3} p3 - Third vertex of the triangle
   * @returns {number} The signed volume of the tetrahedron
   * 
   * @description
   * Calculates the signed volume of a tetrahedron formed by three points and the origin.
   * The sign indicates the orientation of the triangle (clockwise or counterclockwise).
   * 
   * Formula: V = (p1 · (p2 × p3)) / 6
   */
  signedVolumeOfTriangle(p1, p2, p3) {
    return p1.dot(p2.cross(p3)) / 6.0;
  }

  /**
   * @method setupMaterial
   * @private
   * @param {THREE.Material} material - The material to configure
   * @description Configures material properties including texture settings,
   * color spaces, and UV mapping for optimal rendering.
   */
  setupMaterial(material) {
    if (material.map) {
      material.map.colorSpace = THREE.SRGBColorSpace;
      material.map.flipY = false;
      material.needsUpdate = true;
    }

    ['normalMap', 'roughnessMap', 'metalnessMap'].forEach(mapType => {
      if (material[mapType]) {
        material[mapType].colorSpace = THREE.LinearSRGBColorSpace;
        material[mapType].flipY = false;
      }
    });
  }

  /**
   * @method removeCurrentMap
   * @description Removes the current map from the scene and cleans up associated
   * physics bodies and resources.
   */
  removeCurrentMap() {
    console.groupCollapsed('🧹 Cleaning up current map');
    
    if (this.currentCustomMap) {
      // First, remove all physics bodies
      this.currentCustomMap.traverse((child) => {
        if (child.userData.physicsBody) {
          console.log('Cleaning up physics for:', child.name || 'unnamed mesh');
          
          // Remove from physics world
          this.physicsEngine.physicsWorld.removeRigidBody(child.userData.physicsBody);
          
          // Clean up all Ammo.js objects
          if (child.userData.ammoObjects) {
            child.userData.ammoObjects.forEach(obj => {
              try {
                this.physicsEngine.Ammo.destroy(obj);
              } catch (e) {
                console.warn('Failed to destroy Ammo object:', e);
              }
            });
            delete child.userData.ammoObjects;
          }
          
          delete child.userData.physicsBody;
        }

        // Clean up Three.js resources
        if (child.geometry) {
          child.geometry.dispose();
        }
        if (child.material) {
          if (Array.isArray(child.material)) {
            child.material.forEach(material => {
              Object.values(material).forEach(prop => {
                if (prop && typeof prop.dispose === 'function') {
                  prop.dispose();
                }
              });
              material.dispose();
            });
          } else {
            Object.values(child.material).forEach(prop => {
              if (prop && typeof prop.dispose === 'function') {
                prop.dispose();
              }
            });
            child.material.dispose();
          }
        }
      });

      // Clean up physics visualization
      if (this.physicsPoints) {
        if (this.physicsPoints.geometry) {
          this.physicsPoints.geometry.dispose();
        }
        if (this.physicsPoints.material) {
          this.physicsPoints.material.dispose();
        }
        this.scene.remove(this.physicsPoints);
        this.physicsPoints = null;
      }

      // Remove from scene
      this.scene.remove(this.currentCustomMap);
      this.currentCustomMap = null;

      // Force garbage collection hint
      if (window.gc) {
        window.gc();
      }
      
      console.log('✅ Map cleanup complete');
    } else {
      console.log('No map to clean up');
    }
    
    console.groupEnd();
  }

  /**
   * @method loadGLTF
   * @private
   * @param {string} url - URL of the GLTF/GLB file to load
   * @returns {Promise<THREE.GLTF>} Promise that resolves with the loaded GLTF data
   * @description Loads a GLTF/GLB file using Three.js GLTFLoader.
   * Handles loading progress and error reporting.
   */
  loadGLTF(url) {
    return new Promise((resolve, reject) => {
      console.groupCollapsed('📦 Loading GLTF/GLB');
      
      this.loader.load(
        url,
        (gltf) => {
          console.log('✅ Model loaded successfully');
          console.groupEnd();
          resolve(gltf);
        },
        (progress) => {
          const percent = (progress.loaded / progress.total * 100).toFixed(0);
          if (percent % 20 === 0) { // Only log every 20%
            console.log(`📥 Loading: ${percent}%`);
          }
        },
        (error) => {
          console.error('❌ Loading failed:', error);
          console.groupEnd();
          reject(error);
        }
      );
    });
  }

  /**
   * @method loadPLY
   * @private
   * @param {string} url - URL of the PLY file to load
   * @returns {Promise<THREE.BufferGeometry>} Promise that resolves with the loaded geometry
   */
  loadPLY(url) {
    return new Promise((resolve, reject) => {
      console.groupCollapsed('📦 Loading PLY');
      
      this.plyLoader.load(
        url,
        (geometry) => {
          if (!geometry.attributes.normal) {
            geometry.computeVertexNormals();
          }
          console.log('✅ Model loaded successfully');
          console.groupEnd();
          resolve(geometry);
        },
        (progress) => {
          const percent = (progress.loaded / progress.total * 100).toFixed(0);
          if (percent % 20 === 0) { // Only log every 20%
            console.log(`📥 Loading: ${percent}%`);
          }
        },
        (error) => {
          console.error('❌ Loading failed:', error);
          console.groupEnd();
          reject(error);
        }
      );
    });
  }

  // Add this new method to toggle physics visualization
  togglePhysicsDebug(enabled) {
    this.debugPhysics = enabled;
    if (this.physicsPoints) {
        this.physicsPoints.visible = enabled;
    }
  }
}

export default CustomMapLoader; 