import * as THREE from 'three';
import { pixelDataToTensor } from './imageHelper';
import { modelConfigs } from '../inference/modelConfig';
import { createRoot } from 'react-dom/client';
import InferenceResults from '../components/InferenceResults';

const DEBUG = false; // Toggle for verbose logging
let boundingBoxOverlay; // Declare at module level

// Add performance monitoring
const perfMetrics = {
  captureTime: 0,
  preprocessTime: 0,
  inferenceTime: 0,
  renderTime: 0,
  totalPipelineTime: 0
};

function logPerformance(stage, time) {
  if (!DEBUG) return;
  
  perfMetrics[stage] = time;
  // Only log summary metrics periodically (every 30 frames)
  if (Math.random() < 0.033) { // ~1/30 chance
    console.log('📊 Performance Summary:', {
      ...perfMetrics,
      totalTime: Object.values(perfMetrics).reduce((a, b) => a + b, 0)
    });
  }
}

// Initialize the inference worker
const inferenceWorker = new Worker(new URL('../workers/inferenceWorker.js', import.meta.url), {
  type: 'module'
});

// Initialize the worker
inferenceWorker.postMessage({ type: 'init' });

// Listen for messages from the worker
inferenceWorker.onmessage = (e) => {
  const { type, predictions, error, timings, debugData, modelName } = e.data;
  
  if (type === 'initialized') {
    console.log('✅ Inference Worker initialized');
  }

  if (type === 'inferenceResult') {
    console.log('🎯 Received predictions:', predictions);
    
    const renderStart = performance.now();
    if (DEBUG) {
      console.log('✨ Inference results:', predictions);
      if (timings) {
        console.log('⚡ Pipeline timings:', timings);
      }
      if (debugData) {
        console.log('🔍 Debug data:', {
          original: {
            width: debugData.original?.width,
            height: debugData.original?.height,
            dataLength: debugData.original?.data?.length
          },
          preprocessed: {
            width: debugData.preprocessed?.width,
            height: debugData.preprocessed?.height,
            dataLength: debugData.preprocessed?.data?.length
          },
          detection: {
            width: debugData.detection?.width,
            height: debugData.detection?.height,
            dataLength: debugData.detection?.data?.length
          }
        });
      }
    }
    
    // Dispatch debug data to any listeners
    const debugEvent = new CustomEvent('inferenceDebug', {
      detail: {
        type: 'inferenceResult',
        modelName,
        debugData,
        timings
      }
    });
    window.dispatchEvent(debugEvent);
    
    // Dispatch predictions event ONCE here
    window.dispatchEvent(new CustomEvent('inferenceUpdate', {
      detail: { predictions }
    }));
    
    // Update bounding boxes without dispatching another event
    updateBoundingBoxes(predictions);
    
    const renderTime = performance.now() - renderStart;
    logPerformance('renderTime', renderTime);
    
    // Calculate total pipeline time
    perfMetrics.totalPipelineTime = 
      perfMetrics.captureTime + 
      (timings?.preprocessTime || 0) + 
      (timings?.inferenceTime || 0) + 
      perfMetrics.renderTime;
    
    if (DEBUG) {
      console.log('📊 Total pipeline time:', perfMetrics.totalPipelineTime.toFixed(2) + 'ms');
    }
  }

  if (type === 'error') {
    console.error('❌ Inference Error:', error);
  }
};

/**
 * @function createFPVDisplay
 * @description Creates the First Person View display and returns the FPV renderer.
 * @param {HTMLElement} mountElement - The element to mount the FPV display to.
 * @returns {THREE.WebGLRenderer} The FPV renderer.
 */
export function createFPVDisplay(mountElement) {
    const startTime = performance.now();
    const logs = {
        setup: [],
        timing: {}
    };
    
    // Create a container for the FPV display
    const fpvContainer = document.createElement('div');
    Object.assign(fpvContainer.style, {
        position: 'absolute',
        top: '2%',
        left: '5%',
        width: '30%',
        maxWidth: '384px',
        aspectRatio: '16 / 9',
        border: '2px solid white',
        zIndex: '10',
        overflow: 'hidden'
    });
    
    mountElement.appendChild(fpvContainer);
    logs.setup.push('Container mounted');

    const fpvCanvas = document.createElement('canvas');
    fpvCanvas.style.width = '100%';
    fpvCanvas.style.height = '100%';
    fpvContainer.appendChild(fpvCanvas);
    logs.setup.push('Canvas created');

    const fpvRenderer = new THREE.WebGLRenderer({ canvas: fpvCanvas });
    logs.setup.push('Renderer initialized');
    
    // Function to update renderer size
    const updateRendererSize = () => {
        const width = fpvContainer.clientWidth;
        const height = fpvContainer.clientHeight;
        fpvCanvas.width = width;
        fpvCanvas.height = height;
        fpvRenderer.setSize(width, height, false);
        if (DEBUG) logs.setup.push(`Size updated: ${width}x${height}`);
    };

    updateRendererSize();
    window.addEventListener('resize', updateRendererSize);

    // Create overlay for bounding boxes
    boundingBoxOverlay = document.createElement('div');
    Object.assign(boundingBoxOverlay.style, {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        pointerEvents: 'none'
    });
    fpvContainer.appendChild(boundingBoxOverlay);
    logs.setup.push('Overlay created');

    const setupTime = performance.now() - startTime;
    logs.timing = {
        total: `${setupTime.toFixed(1)}ms`
    };

    if (DEBUG) {
        console.groupCollapsed('🎥 FPV Display Setup');
        console.log('📋 Steps:', logs.setup.join(' → '));
        console.log('⏱️ Time:', logs.timing.total);
        console.groupEnd();
    }

    return { fpvRenderer, updateRendererSize };
}

/**
 * @function captureFPVSnapshot
 * @description Captures a snapshot from the FPV display and returns it as a pixel array.
 * @param {THREE.WebGLRenderer} fpvRenderer - The FPV renderer.
 * @returns {Uint8ClampedArray} A pixel array of the FPV snapshot.
 */
export function captureFPVSnapshot(fpvRenderer) {
    const startTime = performance.now();
    
    if (fpvRenderer && fpvRenderer.domElement) {
      const gl = fpvRenderer.getContext();
      const width = fpvRenderer.domElement.width;
      const height = fpvRenderer.domElement.height;
  
      if (DEBUG) {
        if (Math.random() < 0.1) {
          console.log('📸 Frame dimensions:', { width, height });
        }
      }
  
      if (width === 0 || height === 0) {
        console.error('❌ Canvas dimensions invalid');
        return null;
      }
  
      let rgbaData = null;
      try {
        // Save the current viewport and framebuffer state
        const viewport = gl.getParameter(gl.VIEWPORT);
        const framebuffer = gl.getParameter(gl.FRAMEBUFFER_BINDING);
        
        // Set viewport to match FPV dimensions
        gl.viewport(0, 0, width, height);
        
        // Read RGBA data first (this is the only format WebGL provides)
        rgbaData = new Uint8Array(width * height * 4);
        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, rgbaData);
        
        // Restore previous viewport and framebuffer state
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
        gl.viewport(viewport[0], viewport[1], viewport[2], viewport[3]);
        
        // Convert to RGB and flip vertically
        const rgbData = new Uint8Array(width * height * 3);
        for (let y = 0; y < height; y++) {
          for (let x = 0; x < width; x++) {
            // Flip Y coordinate
            const srcY = height - 1 - y;
            
            const srcIdx = (srcY * width + x) * 4;  // Source index in RGBA
            const dstIdx = (y * width + x) * 3;     // Destination index in RGB
            
            rgbData[dstIdx] = rgbaData[srcIdx];     // R
            rgbData[dstIdx + 1] = rgbaData[srcIdx + 1]; // G
            rgbData[dstIdx + 2] = rgbaData[srcIdx + 2]; // B
          }
        }
        
        const captureTime = performance.now() - startTime;
        logPerformance('captureTime', captureTime);
        
        return rgbData;
      } catch (error) {
        console.error('❌ Frame capture error:', error.message);
        return null;
      } finally {
        // Clean up the temporary RGBA data
        if (rgbaData) {
          rgbaData = null;
        }
        // Clear any remaining WebGL errors
        while (gl.getError() !== gl.NO_ERROR) {
          // Clear error queue
        }
      }
    } else {
      if (DEBUG) console.error('❌ FPV Renderer not initialized');
      return null;
    }
}

/**
 * @function downloadImage
 * @description Downloads the image from a base64 data URL.
 * @param {string} dataURL - The base64 data URL of the image.
 * @param {string} filename - The name of the file to be downloaded.
 */
export function downloadImage(dataURL, filename = 'snapshot.png') {
  const link = document.createElement('a');
  link.href = dataURL;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * @function displayImage
 * @description Displays the image from pixel data in an img element.
 * @param {Uint8ClampedArray} pixelData - The pixel data of the image.
 * @param {number} width - The width of the image.
 * @param {number} height - The height of the image.
 */
export function displayImage(pixelData, width, height) {
  if (!pixelData || pixelData.length !== width * height * 4) {
    console.error('Invalid pixel data or dimensions');
    return;
  }

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const imageData = new ImageData(new Uint8ClampedArray(pixelData), width, height);
  ctx.putImageData(imageData, 0, 0);

  const img = document.createElement('img');
  img.src = canvas.toDataURL();
  img.style.position = 'absolute';
  img.style.bottom = '10px';
  img.style.left = '10px';
  img.style.border = '2px solid white';
  img.style.zIndex = '1000'; // Ensure it is on top of other elements
  document.body.appendChild(img);
}


/**
 * Runs inference on the captured frame using the worker.
 * @param {Uint8ClampedArray} pixelData - The pixel data from the snapshot.
 * @param {number[]} dims - The dimensions of the tensor.
 */
export function runInference(pixelData, dims, modelName) {
  const startTime = performance.now();
  
  // Get the correct input size from the model config
  const config = modelConfigs[modelName];
  if (!config) {
    console.error('❌ Model config not found:', modelName);
    return;
  }
  
  // Validate dimensions
  const [targetHeight, targetWidth] = config.inputSize;
  if (!pixelData || pixelData.length === 0) {
    console.error('❌ Invalid pixel data');
    return;
  }

  const ogDims = [1, 3, dims[2], dims[3]];
  const newDims = [1, 3, targetHeight, targetWidth];
  
  try {
    inferenceWorker.postMessage(
      { 
        type: 'inference', 
        data: { pixelData, dims: newDims, ogDims, modelName, debugBool: DEBUG } 
      }, 
      [pixelData.buffer]
    );
    
    if (DEBUG) {
      const messageTime = performance.now() - startTime;
      // Log only occasionally
      if (Math.random() < 0.1) {
        console.log(`📤 Worker message sent in ${messageTime.toFixed(2)}ms`);
      }
    }
  } catch (error) {
    console.error('❌ Inference message error:', error.message);
  }
}

function updateBoundingBoxes(predictions) {
  if (!boundingBoxOverlay) {
    console.error('❌ Bounding box overlay not initialized');
    return;
  }

  const startTime = performance.now();
  
  // Clear previous boxes
  boundingBoxOverlay.innerHTML = '';

  // Only draw bounding boxes for detection results
  if (Array.isArray(predictions) && predictions.length > 0 && predictions[0].hasOwnProperty('box')) {
    const overlayRect = boundingBoxOverlay.getBoundingClientRect();
    const overlayWidth = overlayRect.width;
    const overlayHeight = overlayRect.height;

    predictions
      .filter(pred => pred.score > 0.25)
      .forEach(pred => {
        if (pred.box) {
          const [x1, y1, x2, y2] = pred.box;
          const color = getRandomColor(pred.class);
          
          // Create box element
          const boxDiv = document.createElement('div');
          boxDiv.style.position = 'absolute';
          boxDiv.style.left = `${x1 * 100}%`;
          boxDiv.style.top = `${y1 * 100}%`;
          boxDiv.style.width = `${(x2 - x1) * 100}%`;
          boxDiv.style.height = `${(y2 - y1) * 100}%`;
          boxDiv.style.border = `2px solid ${color}`;
          boxDiv.style.boxSizing = 'border-box';
          boxDiv.style.pointerEvents = 'none';

          boundingBoxOverlay.appendChild(boxDiv);
        }
      });
  }

  const updateTime = performance.now() - startTime;
  if (DEBUG) console.log(`🎨 Results updated in ${updateTime.toFixed(2)}ms`);
}

// Helper function to generate consistent colors for classes
const colorCache = new Map();
function getRandomColor(className) {
  if (colorCache.has(className)) {
    return colorCache.get(className);
  }
  
  const hue = Math.abs(hashString(className) % 360);
  const color = `hsl(${hue}, 70%, 50%)`;
  colorCache.set(className, color);
  return color;
}

// Simple string hash function
function hashString(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash;
}
