/**
 * @fileoverview WebAssembly Geometry Processor for Drone SDK
 * 
 * This module provides a high-performance geometry processing interface using WebAssembly,
 * compiled from Rust. It handles 3D geometry calculations crucial for drone simulation,
 * including bounds calculation and mesh processing.
 * 
 * The geometry processor is designed to work efficiently with large mesh data sets,
 * leveraging Rust's performance through WebAssembly for computationally intensive tasks.
 * 
 * @module wasmGeometryProcessor
 */

/** @type {Object|null} Global WASM module instance */
let wasm = null;

/**
 * Initializes the WebAssembly module for geometry processing
 * Must be called before creating any GeometryProcessor instances
 * 
 * @async
 * @throws {Error} If WASM module initialization fails
 * @example
 * await initWasm();
 * const processor = new GeometryProcessor(vertices);
 */
export async function initWasm() {
    if (wasm) return;
    
    try {
        const startTime = performance.now();
        const logs = {
            steps: [],
            timing: {},
            details: {}
        };

        logs.steps.push('Starting initialization');
        const module = await import('../../rust-wasm/pkg');
        logs.details.moduleKeys = Object.keys(module);
        logs.steps.push('Module imported');
        
        await module.default();
        logs.steps.push('Module initialized');
        
        module.init_panic_hook();
        logs.steps.push('Panic hook set');
        
        wasm = module;
        logs.steps.push('Setup complete');
        
        logs.timing.total = `${Math.round(performance.now() - startTime)}ms`;

        console.groupCollapsed('🔄 Initialiizing WASM module');
        console.log('📋 Steps:', logs.steps.join(' → '));
        console.log('⚙️ Module:', logs.details.moduleKeys);
        console.log('⏱️ Time:', logs.timing.total);
        console.groupEnd();
    } catch (error) {
        console.group('❌ WASM Initialization Failed');
        console.error('Error:', error);
        console.trace('Stack trace:');
        console.groupEnd();
        throw error;
    }
}

/**
 * Handles geometry processing operations using WebAssembly
 * Provides efficient calculations for 3D mesh operations
 * 
 * @class
 * @example
 * const processor = new GeometryProcessor(vertices);
 * const bounds = processor.calculate_bounds();
 * processor.dispose(); // Clean up when done
 */
export class GeometryProcessor {
    /**
     * Creates a new geometry processor instance
     * 
     * @param {Float32Array|number[]} vertices - Array of vertex coordinates in format [x1,y1,z1,x2,y2,z2,...]
     * @throws {Error} If WASM module is not initialized or vertex data is invalid
     */
    constructor(vertices) {
        if (!wasm) {
            console.error('❌ WASM module not initialized');
            throw new Error('WASM module not initialized');
        }
        console.log('🦀 Creating GeometryProcessor with vertices:', vertices.length);
        this.vertices = vertices instanceof Float32Array ? vertices : Float32Array.from(vertices);
        this.processor = new wasm.GeometryProcessor(this.vertices);
        console.log('🦀 GeometryProcessor created successfully');
    }

    /**
     * Calculates the bounding box of the geometry
     * 
     * @returns {Object} Bounds object containing min and max points
     * @property {Float32Array} min - Minimum point [x,y,z]
     * @property {Float32Array} max - Maximum point [x,y,z]
     * @throws {Error} If the processor has been disposed or calculation fails
     * 
     * @example
     * const bounds = processor.calculate_bounds();
     * console.log('Width:', bounds.max[0] - bounds.min[0]);
     */
    calculate_bounds() {
        if (!this.processor) {
            console.error('❌ Geometry processor not initialized');
            throw new Error('Geometry processor not initialized');
        }
        const startTime = performance.now();
        console.log('🦀 Calculating bounds in Rust...');
        const bounds = this.processor.calculate_bounds();
        console.log('🦀 Bounds calculated:', {
            timeMs: Math.round(performance.now() - startTime),
            min: Array.from(bounds.min),
            max: Array.from(bounds.max),
            dimensions: {
                width: bounds.max[0] - bounds.min[0],
                height: bounds.max[1] - bounds.min[1],
                depth: bounds.max[2] - bounds.min[2]
            }
        });
        return bounds;
    }

    /**
     * Cleans up WASM resources to prevent memory leaks
     * Must be called when the processor is no longer needed
     * 
     * @example
     * const processor = new GeometryProcessor(vertices);
     * // ... use processor ...
     * processor.dispose(); // Clean up when done
     */
    dispose() {
        if (this.processor) {
            console.log('🦀 Disposing GeometryProcessor');
            this.processor.free();
            this.processor = null;
        }
    }
} 