import * as THREE from 'three';
import StabilizationVisualizer from '../utils/stabilizationVisualizer';
import { DronePhysics, Vector3, Quaternion } from '../../rust-wasm/pkg';

/**
 * @class PIDController
 * @description Simple PID controller for stabilizing drone orientation.
 */
class PIDController {
  constructor(kp = 0.05, ki = 0.005, kd = 0.01, controls) {
    this.kp = kp;
    this.ki = ki;
    this.kd = kd;
    this.controls = controls;
    this.setpoint = { roll: 0, pitch: 0, yaw: 0 };
    this.integral = new THREE.Vector3(0, 0, 0);
    this.lastError = new THREE.Vector3(0, 0, 0);
    this.maxIntegral = 1; // Reduced further to limit sensitivity
  }

  compute(currentPitch, currentRoll, currentYaw, deltaTime) {
    // Calculate error
    const errorRoll = this.setpoint.roll - currentRoll;
    const errorPitch = this.setpoint.pitch - currentPitch;
    const errorYaw = this.setpoint.yaw - currentYaw;

    // Integrate error with clamping for windup prevention
    this.integral.x = THREE.MathUtils.clamp(this.integral.x + errorRoll * deltaTime, -this.maxIntegral, this.maxIntegral);
    this.integral.y = THREE.MathUtils.clamp(this.integral.y + errorPitch * deltaTime, -this.maxIntegral, this.maxIntegral);
    this.integral.z = THREE.MathUtils.clamp(this.integral.z + errorYaw * deltaTime, -this.maxIntegral, this.maxIntegral);

    // Calculate derivative
    const derivative = new THREE.Vector3(
      (errorRoll - this.lastError.x) / deltaTime,
      (errorPitch - this.lastError.y) / deltaTime,
      (errorYaw - this.lastError.z) / deltaTime
    );

    this.lastError.set(errorRoll, errorPitch, errorYaw);

    // Calculate PID output
    const output = new THREE.Vector3(
      this.kp * errorRoll + this.ki * this.integral.x + this.kd * derivative.x,
      this.kp * errorPitch + this.ki * this.integral.y + this.kd * derivative.y,
      this.kp * errorYaw + this.ki * this.integral.z + this.kd * derivative.z
    );

    // Clamp output to ensure control signals are within bounds (-1 to 1)
    output.clamp(new THREE.Vector3(-1, -1, -1), new THREE.Vector3(1, 1, 1));

    // Apply baseline offsets and invert if necessary
    return {
      roll: 1452 - output.x * 500,    // Adjusted for baseline offset, inverted if needed
      pitch: 1548 - output.y * 500,   // Adjusted for baseline offset, inverted if needed
      yaw: 1500 + output.z * 500,
      throttle: this.controls.getControlInputs().throttle * 3000
    };
  }
}



/**
 * @class PhysicsEngine
 * @description Manages the physics simulation for the drone and its environment.
 * This class handles the integration of Ammo.js physics with Three.js rendering.
 */
class PhysicsEngine {
  /**
   * @constructor
   * @param {DroneControls} controls - The drone controls instance.
   */
  constructor(controls) {
    this.controls = controls;
    this.rigidBodies = [];
    this.physicsWorld = null;
    this.Ammo = null;
    this.tmpTransformation = null;
    this.clockDelta = 0;  
    this.lastTime = performance.now();
    this.droneMass = 0.25; // 250g
    this.droneRigidBody = null;
    this.scene = null;
    this.lastLogTime = 0;
    this.logInterval = 500;
    this.debugMode = false;
    this.isHorizonMode = false;
    this.pidController = null;
    this.stabilizationVisualizer = null;
    this.addLog = console.log;
    
    // For performance measurement
    this.lastPerformanceLog = 0;
    this.performanceLogInterval = 1000; // Log every second
    this.frameTimeSum = 0;
    this.frameCount = 0;
  }

  /**
   * @method init
   * @async
   * @param {THREE.Scene} scene - The Three.js scene to which physics objects will be added.
   * @description Initializes the physics engine, loading Ammo.js and setting up the physics world.
   */
  async init(scene) {
    console.groupCollapsed('🎮 PhysicsEngine: Initialization');
    console.log('📋 Initial State:', {
      hasScene: !!scene,
      hasDrone: !!scene?.drone
    });

    this.scene = scene;
    const logs = {
      steps: [],
      modules: {}
    };
    
    try {
      // Initialize WASM module
      await import('../../rust-wasm/pkg');
      logs.steps.push('WASM module loaded');
      this.dronePhysics = new DronePhysics(0.5);
      logs.modules.wasm = true;
      
      await this.loadAmmo();
      logs.steps.push('Ammo.js loaded');
      logs.modules.ammo = !!this.Ammo;
      
      if (!this.Ammo) {
        throw new Error('Ammo.js could not be loaded!');
      }

      this.setupPhysicsWorld();
      logs.steps.push('Physics world created');
      
      await this.createPhysicsObjects();
      logs.steps.push('Physics objects initialized');
      
      this.tmpTransformation = new this.Ammo.btTransform();
      logs.steps.push('Transform helper created');

      // Initialize PID controller and visualizer
      this.pidController = new PIDController(.05, 0.005, .01, this.controls);
      this.stabilizationVisualizer = new StabilizationVisualizer(document.body);
      this.stabilizationVisualizer.setVisible(true);
      logs.steps.push('Controllers initialized');

      console.log('📋 Steps:', logs.steps.join(' → '));
      console.log('🔧 Modules:', logs.modules);
      console.log('✅ Final State:', {
        hasPhysicsWorld: !!this.physicsWorld,
        hasDroneBody: !!this.droneRigidBody,
        hasWasmModule: !!this.dronePhysics
      });
      console.groupEnd();
    } catch (error) {
      console.group('❌ PhysicsEngine: Initialization Failed');
      console.error('Error:', error);
      console.trace('Stack trace:');
      console.groupEnd();
      throw error;
    }
  }

  /**
   * @method loadAmmo
   * @private
   * @returns {Promise} A promise that resolves when Ammo.js is loaded.
   * @description Loads the Ammo.js library.
   */
  loadAmmo() {
    return new Promise((resolve) => {
      if (window.Ammo) {
        this.Ammo = window.Ammo;
        resolve();
      } else {
        window.addEventListener('ammo-loaded', () => {
          this.Ammo = window.Ammo;
          resolve();
        });
      }
    });
  }

  /**
   * @method setupPhysicsWorld
   * @private
   * @description Sets up the Ammo.js physics world with default settings.
   */
  setupPhysicsWorld() {
    const collisionConfiguration = new this.Ammo.btDefaultCollisionConfiguration();
    const dispatcher = new this.Ammo.btCollisionDispatcher(collisionConfiguration);
    const broadphase = new this.Ammo.btDbvtBroadphase();
    const solver = new this.Ammo.btSequentialImpulseConstraintSolver();

    this.physicsWorld = new this.Ammo.btDiscreteDynamicsWorld(
      dispatcher,
      broadphase,
      solver,
      collisionConfiguration
    );
    this.physicsWorld.setGravity(new this.Ammo.btVector3(0, -9.81, 0));

    // Increase solver iterations for better accuracy
    const solverInfo = this.physicsWorld.getSolverInfo();
    if (solverInfo.m_numIterations !== undefined) {
      solverInfo.m_numIterations = 20; // Increased from default
    } else {
      console.warn('Unable to set solver iterations. This may affect simulation accuracy.');
    }
  }

  /**
   * @method createPhysicsObjects
   * @private
   * @description Creates the initial physics objects in the world (ground and drone).
   */
  createPhysicsObjects() {
    this.createGround();
    this.createDronePhysics();
  }

  /**
   * @method createGround
   * @private
   * @description Creates a static ground plane in the physics world.
   */
  createGround() {
    const groundShape = new this.Ammo.btBoxShape(new this.Ammo.btVector3(500, 0.5, 500));
    const groundTransform = new this.Ammo.btTransform();
    groundTransform.setIdentity();
    groundTransform.setOrigin(new this.Ammo.btVector3(0, -0.5, 0));

    const mass = 0;
    const localInertia = new this.Ammo.btVector3(0, 0, 0);
    const motionState = new this.Ammo.btDefaultMotionState(groundTransform);
    const rbInfo = new this.Ammo.btRigidBodyConstructionInfo(mass, motionState, groundShape, localInertia);
    const body = new this.Ammo.btRigidBody(rbInfo);

    this.physicsWorld.addRigidBody(body);
  }

  /**
   * @method createDronePhysics
   * @private
   * @description Creates the physics representation of the drone.
   */
  createDronePhysics() {
    if (!this.scene.drone) {
      console.log('⏳ Waiting for drone to be ready...');
      setTimeout(() => this.createDronePhysics(), 100);
      return;
    }

    console.groupCollapsed('🎮 PhysicsEngine: Drone Physics Setup');
    const logs = {
      dimensions: {},
      physics: {}
    };

    const droneMesh = this.scene.drone;
    
    // Clear any existing physics body with proper cleanup
    if (droneMesh.userData.physicsBody) {
      console.log('🧹 Cleaning up existing drone physics body');
      this.cleanupPhysicsBody(droneMesh);
    }

    const bbox = new THREE.Box3().setFromObject(droneMesh);
    const size = bbox.getSize(new THREE.Vector3());
    logs.dimensions = size.toArray();
    logs.position = droneMesh.getWorldPosition(new THREE.Vector3()).toArray();
    
    // Create physics body with size matching the actual model
    const shape = new this.Ammo.btBoxShape(new this.Ammo.btVector3(
      size.x / 2,  // Half-extents
      size.y / 2,
      size.z / 2
    ));
    
    const transform = new this.Ammo.btTransform();
    transform.setIdentity();
    
    // Use the initial position from DroneScene or current position if not available
    const initialPos = this.scene.initialDronePosition || droneMesh.position;
    transform.setOrigin(new this.Ammo.btVector3(
      initialPos.x,
      initialPos.y,
      initialPos.z
    ));

    const mass = 1;  // 1kg mass for the drone
    const localInertia = new this.Ammo.btVector3(0, 0, 0);
    shape.calculateLocalInertia(mass, localInertia);

    const motionState = new this.Ammo.btDefaultMotionState(transform);
    const rbInfo = new this.Ammo.btRigidBodyConstructionInfo(mass, motionState, shape, localInertia);
    const body = new this.Ammo.btRigidBody(rbInfo);

    // Store Ammo.js objects for cleanup
    droneMesh.userData.ammoObjects = {
      shape,
      transform,
      motionState,
      rbInfo,
      localInertia,
      body
    };

    // Adjust physics properties
    body.setDamping(0.7, 0.7);
    body.setFriction(0.5);
    body.setRestitution(0.2);
    body.setActivationState(4); // DISABLE_DEACTIVATION

    // Store references
    this.droneRigidBody = body;
    droneMesh.userData.physicsBody = body;

    this.rigidBodies.push({
      mesh: droneMesh,
      body: body
    });

    logs.physics = {
      position: initialPos.toArray(),
      size: size.toArray(),
      mass: mass,
      inertia: [localInertia.x(), localInertia.y(), localInertia.z()]
    };

    console.log('📐 Dimensions:', logs.dimensions);
    console.log('📍 Position:', logs.position);
    console.log('⚛️ Physics:', logs.physics);
    console.groupEnd();

    this.physicsWorld.addRigidBody(body);
    return body;
  }

  cleanupPhysicsBody(mesh) {
    if (!mesh.userData.physicsBody) return;

    // Remove from physics world
    this.physicsWorld.removeRigidBody(mesh.userData.physicsBody);

    // Clean up all Ammo.js objects
    if (mesh.userData.ammoObjects) {
      const ammoObjects = mesh.userData.ammoObjects;
      Object.values(ammoObjects).forEach(obj => {
        if (obj && typeof this.Ammo.destroy === 'function') {
          try {
            this.Ammo.destroy(obj);
          } catch (e) {
            console.warn('Failed to destroy Ammo object:', e);
          }
        }
      });
      delete mesh.userData.ammoObjects;
    }

    // Remove from rigidBodies array
    this.rigidBodies = this.rigidBodies.filter(rb => rb.mesh !== mesh);
    
    // Clear physics body reference
    delete mesh.userData.physicsBody;
    if (this.droneRigidBody === mesh.userData.physicsBody) {
      this.droneRigidBody = null;
    }
  }

  /**
   * @method getDroneMass
   * @returns {Object} Object containing mass information for the drone
   * @description Gets the current mass of the drone and any child bodies
   */
  getDroneMass() {
    const massInfo = {
      mainBody: 0,
      childBodies: [],
      total: 0,
      status: 'unknown'
    };

    if (!this.scene?.drone) {
      massInfo.status = 'No drone in scene';
      return massInfo;
    }

    if (!this.droneRigidBody) {
      massInfo.status = 'No physics body initialized';
      return massInfo;
    }

    try {
      // Get mass info from construction info
      const ci = this.droneRigidBody.getCollisionShape();
      if (!ci) {
        massInfo.status = 'No collision shape found';
        return massInfo;
      }

      // Get mass from rigid body construction info
      const transform = new this.Ammo.btTransform();
      this.droneRigidBody.getMotionState().getWorldTransform(transform);
      
      // We know we set the mass to 1 in createDronePhysics
      massInfo.mainBody = 1;
      massInfo.status = 'active';

      // Check for any additional physics bodies on drone parts
      this.scene.drone.traverse((child) => {
        if (child.userData.physicsBody && 
            child.userData.physicsBody !== this.droneRigidBody) {
          try {
            // For child bodies, we'll just log their existence
            massInfo.childBodies.push({
              name: child.name || 'unnamed part',
              mass: 'unknown' // We can't reliably get mass from child bodies
            });
          } catch (error) {
            console.warn('Failed to process child body:', child.name);
          }
        }
      });

      massInfo.total = massInfo.mainBody + massInfo.childBodies.length; // Assume 1kg per child body if any

      console.group('🎮 Drone Mass Information');
      console.log('Status:', massInfo.status);
      console.log('Main body mass:', massInfo.mainBody.toFixed(3), 'kg');
      if (massInfo.childBodies.length > 0) {
        console.log('Additional physics bodies found:', massInfo.childBodies);
        console.warn('⚠️ Multiple physics bodies detected on drone!');
      }
      console.log('Total mass:', massInfo.total.toFixed(3), 'kg');
      console.groupEnd();

    } catch (error) {
      massInfo.status = `Error getting mass: ${error.message}`;
      console.warn('Failed to get drone mass:', error);
    }

    return massInfo;
  }

  /**
   * @method update
   * @public
   * @description Updates the physics simulation. Should be called once per frame.
   */
  update(deltaTime) {
    const now = performance.now();
    this.clockDelta = (now - this.lastTime) / 1000;
    this.lastTime = now;

    if (this.physicsWorld && this.droneRigidBody) {
      const orientation = this.getDroneOrientation();
      let pidOutputs = null;

      if (this.isHorizonMode && this.pidController) {
        pidOutputs = this.pidController.compute(
          orientation.pitch,
          orientation.roll,
          orientation.yaw,
          this.clockDelta
        );
        this.controls.setControlInputs(pidOutputs);
      } else {
        // In acro mode, just use raw control inputs
        const rawControls = this.controls.getControlInputs();
        pidOutputs = {
          roll: rawControls.roll * 1500 + 1500,
          pitch: rawControls.pitch * 1500 + 1500,
          yaw: rawControls.yaw * 1500 + 1500,
          throttle: rawControls.throttle * 3000
        };
      }

      // Update visualizer
      if (this.stabilizationVisualizer) {
        this.stabilizationVisualizer.update(
          orientation,
          pidOutputs,
          this.pidController ? this.pidController.setpoint : { roll: 0, pitch: 0, yaw: 0 }
        );
      }

      // Apply physics updates
      this.applyControlsToDrone();
      this.applyAerodynamics();
      this.physicsWorld.stepSimulation(this.clockDelta, 10);
      this.updateMeshPositions();
    }
  }

  /**
   * @method updateMeshPositions
   * @private
   * @description Updates the positions of Three.js meshes based on their physics representations.
   */
  updateMeshPositions() {
    for (let i = 0; i < this.rigidBodies.length; i++) {
      const obj = this.rigidBodies[i];
      const ms = obj.body.getMotionState();
      
      if (ms) {
        ms.getWorldTransform(this.tmpTransformation);
        const p = this.tmpTransformation.getOrigin();
        const q = this.tmpTransformation.getRotation();

        // Only update if position has actually changed
        const currentPos = obj.mesh.position;
        const newPos = new THREE.Vector3(p.x(), p.y(), p.z());
        
        if (!currentPos.equals(newPos)) {
          // Update Three.js mesh position
          obj.mesh.position.copy(newPos);
          obj.mesh.quaternion.set(q.x(), q.y(), q.z(), q.w());

          if (this.debugMode && obj.mesh === this.scene.drone) {
            const now = performance.now();
            if (now - this.lastLogTime > this.logInterval) {
              console.log('Position update:', {
                from: currentPos.clone(),
                to: newPos.clone(),
                delta: newPos.clone().sub(currentPos)
              });
              this.lastLogTime = now;
            }
          }
        }
      }
    }
  }

  /**
   * @method applyAerodynamics
   * @private
   * @description Applies aerodynamic forces to the drone based on its current state.
   */
  applyAerodynamics() {
    const velocity = this.droneRigidBody.getLinearVelocity();
    const speed = velocity.length();
    
    const airDensity = 1.225; // kg/m^3 at sea level

    // Drag Calculations
    const dragCoefficient = 0.47; // Approximate for a cube
    const frontalArea = 0.25; // m^2 (for a cube with side 1m)
    const dragMagnitude = 0.5 * dragCoefficient * frontalArea * airDensity * speed * speed;

    if (speed > 0) {
      const dragForce = new this.Ammo.btVector3(-velocity.x(), -velocity.y(), -velocity.z());
      dragForce.normalize();
      dragForce.op_mul(dragMagnitude);
      this.droneRigidBody.applyCentralForce(dragForce);
    }

    // Lift Calculations
    // Assuming lift is perpendicular to the velocity vector and drone's orientation
    const liftCoefficient = .1; // Example value, should be based on drone's design
    const liftMagnitude = 0.5 * liftCoefficient * airDensity * speed * speed;

    // Calculate lift direction based on drone's orientation
    const droneTransform = this.droneRigidBody.getWorldTransform();
    const rotation = droneTransform.getRotation();
    const threeQuat = new THREE.Quaternion(rotation.x(), rotation.y(), rotation.z(), rotation.w());
    const upVector = new THREE.Vector3(0, 1, 0).applyQuaternion(threeQuat);
    upVector.normalize();

    const liftForce = new this.Ammo.btVector3(upVector.x * liftMagnitude, upVector.y * liftMagnitude, upVector.z * liftMagnitude);
    this.droneRigidBody.applyCentralForce(liftForce);

    // Additional Aerodynamic Moments (Optional)
    // Example: Induced Drag or Torque based on rotation
    // const torqueCoefficient = 0.1;
    // const torqueMagnitude = torqueCoefficient * speed;
    // const torque = new this.Ammo.btVector3(torqueMagnitude, torqueMagnitude, torqueMagnitude);
    // this.droneRigidBody.applyTorque(torque);
  }

  /**
   * @method applyControlsToDrone
   * @private
   * @description Applies control inputs to the drone's physics representation.
   */
  applyControlsToDrone() {
    const startTime = performance.now();
    
    const controls = this.controls.getControlInputs();
    
    // Get drone's current orientation
    const droneTransform = this.droneRigidBody.getWorldTransform();
    const rotation = droneTransform.getRotation();
    
    // Create Rust quaternion from Ammo.js quaternion
    const orientation = new Quaternion(
      rotation.x(),
      rotation.y(),
      rotation.z(),
      rotation.w()
    );

    // Calculate forces using Rust
    const forces = this.dronePhysics.calculate_forces(
      orientation,
      controls.roll,
      controls.pitch,
      controls.yaw,
      controls.throttle
    );

    // Extract thrust and torque from result
    const [thrustArray, torqueArray] = forces;
    
    // Apply thrust
    const thrustWorld = new this.Ammo.btVector3(
      thrustArray[0],
      thrustArray[1],
      thrustArray[2]
    );
    this.droneRigidBody.applyCentralForce(thrustWorld);

    // Apply torque
    const torqueWorld = new this.Ammo.btVector3(
      torqueArray[0],
      torqueArray[1],
      torqueArray[2]
    );
    this.droneRigidBody.applyTorque(torqueWorld);

    // // Measure and log performance
    // const endTime = performance.now();
    // this.frameTimeSum += endTime - startTime;
    // this.frameCount++;

    // const now = performance.now();
    // if (now - this.lastPerformanceLog > this.performanceLogInterval) {
    //   const avgFrameTime = this.frameTimeSum / this.frameCount;
    //   console.log(`Physics calculation average time: ${avgFrameTime.toFixed(3)}ms per frame`);
    //   this.frameTimeSum = 0;
    //   this.frameCount = 0;
    //   this.lastPerformanceLog = now;
    // }
  }

  // Add this method to force-sync positions
  syncPhysicsWithVisual(mesh, body) {
    if (mesh && body) {
      const transform = new this.Ammo.btTransform();
      transform.setIdentity();
      transform.setOrigin(new this.Ammo.btVector3(
        mesh.position.x,
        mesh.position.y,
        mesh.position.z
      ));
      
      const q = mesh.quaternion;
      transform.setRotation(new this.Ammo.btQuaternion(q.x, q.y, q.z, q.w));
      
      body.setWorldTransform(transform);
      body.getMotionState().setWorldTransform(transform);
      body.activate(true);
      
      Ammo.destroy(transform);
    }
  }

  /**
   * @method setHorizonMode
   * @param {boolean} enabled - Whether to enable horizon mode
   */
  setHorizonMode(enabled) {
    this.isHorizonMode = enabled;
    if (enabled) {
      if (!this.pidController) {
        this.pidController = new PIDController(2.0, 0.5, 1.0, this.controls);
      }
      console.log('🔄 Horizon Mode: Enabled 🛡️');
    } else {
      console.log('🎯 Acro Mode: Enabled 🚁');
      if (this.pidController) {
        this.pidController.integral.set(0, 0, 0);
        this.pidController.lastError.set(0, 0, 0);
      }
    }
  }

  /**
   * @method getDroneOrientation
   * @private
   * @returns {Object} Current orientation in Euler angles
   */
  getDroneOrientation() {
    if (!this.droneRigidBody) return { pitch: 0, roll: 0, yaw: 0 };

    const transform = this.droneRigidBody.getWorldTransform();
    const rotation = transform.getRotation();
    const quat = new THREE.Quaternion(rotation.x(), rotation.y(), rotation.z(), rotation.w());
    const euler = new THREE.Euler().setFromQuaternion(quat, 'YXZ');

    return {
      pitch: THREE.MathUtils.radToDeg(euler.x),
      roll: THREE.MathUtils.radToDeg(euler.z),
      yaw: THREE.MathUtils.radToDeg(-euler.y)
    };
  }

  /**
   * @method clearDroneForces
   * @public
   * @description Clears all forces and torques acting on the drone. This method:
   * - Clears accumulated forces and torques
   * - Resets linear and angular velocities
   * - Ensures the physics body is active
   * - Verifies the force reset
   * 
   * @returns {boolean} True if forces were cleared successfully, false if drone physics body doesn't exist
   */
  clearDroneForces() {
    console.groupCollapsed('🎮 PhysicsEngine: Clear Forces');
    
    if (!this.droneRigidBody) {
      console.warn('⚠️ Cannot clear forces: drone physics body not found');
      console.groupEnd();
      return false;
    }

    try {
      // Create zero vectors for resetting velocities and forces
      const zero = new this.Ammo.btVector3(0, 0, 0);
      const zeroForce = new this.Ammo.btVector3(0, 0, 0);
      const zeroTorque = new this.Ammo.btVector3(0, 0, 0);

      // Reset velocities
      this.droneRigidBody.setLinearVelocity(zero);
      this.droneRigidBody.setAngularVelocity(zero);

      // Clear forces by applying zero force and torque
      this.droneRigidBody.applyCentralForce(zeroForce);
      this.droneRigidBody.applyTorque(zeroTorque);
      
      // Set damping to temporarily stop movement
      this.droneRigidBody.setDamping(0.99, 0.99);
      
      // Ensure the body is active
      this.droneRigidBody.activate(true);

      // Verify forces are cleared
      const linearVel = this.droneRigidBody.getLinearVelocity();
      const angularVel = this.droneRigidBody.getAngularVelocity();

      const logs = {
        linearVelocity: {
          x: linearVel.x(),
          y: linearVel.y(),
          z: linearVel.z()
        },
        angularVelocity: {
          x: angularVel.x(),
          y: angularVel.y(),
          z: angularVel.z()
        }
      };

      console.log('📋 Forces cleared:', logs);
      console.groupEnd();
      return true;
    } catch (error) {
      console.group('❌ PhysicsEngine: Force Clear Failed');
      console.error('Error:', error);
      console.groupEnd();
      return false;
    }
  }

  // For performance logging (if needed)
  logPerformance() {
    const now = performance.now();
    if (now - this.lastPerformanceLog > this.performanceLogInterval) {
      console.groupCollapsed('🎮 PhysicsEngine: Performance');
      const avgFrameTime = this.frameTimeSum / this.frameCount;
      console.log('⏱️ Average frame time:', `${avgFrameTime.toFixed(3)}ms`);
      console.log('🔄 Frame count:', this.frameCount);
      console.groupEnd();
      
      this.frameTimeSum = 0;
      this.frameCount = 0;
      this.lastPerformanceLog = now;
    }
  }

  /**
   * @method setupDroneAfterMap
   * @description Sets up drone physics after a map has been loaded
   * @returns {Promise<boolean>} Resolves when drone physics are set up
   */
  setupDroneAfterMap() {
    return new Promise((resolve, reject) => {
      console.group('🎮 PhysicsEngine: Setting up drone after map');
      
      const maxAttempts = 50; // 5 seconds max
      let attempts = 0;

      const trySetup = () => {
        if (this.scene.drone) {
          // Clear any existing physics body first
          if (this.droneRigidBody) {
            console.log('🧹 Cleaning up existing drone physics');
            this.physicsWorld.removeRigidBody(this.droneRigidBody);
            this.droneRigidBody = null;
            
            // Also clean up references in rigidBodies array
            this.rigidBodies = this.rigidBodies.filter(rb => rb.mesh !== this.scene.drone);
            
            // Clear the physics body reference from the drone mesh
            if (this.scene.drone.userData.physicsBody) {
              delete this.scene.drone.userData.physicsBody;
            }
          }

          // Create new physics body
          const body = this.createDronePhysics();
          if (body) {
            console.log('✅ Drone physics created successfully');
            console.groupEnd();
            resolve(true);
          } else {
            console.warn('⚠️ Failed to create drone physics body');
            console.groupEnd();
            reject(new Error('Failed to create drone physics body'));
          }
        } else if (attempts++ < maxAttempts) {
          console.log(`⏳ Waiting for drone... (attempt ${attempts}/${maxAttempts})`);
          setTimeout(trySetup, 100);
        } else {
          console.warn('❌ Timeout waiting for drone');
          console.groupEnd();
          reject(new Error('Timeout waiting for drone to be ready'));
        }
      };

      trySetup();
    });
  }
}

export default PhysicsEngine;
