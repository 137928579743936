import React, { useState, useCallback, useEffect } from 'react';
import { FaArrowLeft, FaBrain, FaMicrochip, FaChartLine, FaBug } from 'react-icons/fa';
import * as styles from './ModelHub.module.css';

// Get reference to the worker
const inferenceWorker = new Worker(new URL('../workers/inferenceWorker.js', import.meta.url), {
  type: 'module'
});

const DEFAULT_METRICS = {
  totalTime: 0,
  fps: 0,
  memory: 0,
  preprocessTime: 0,
  inferenceTime: 0,
  postprocessTime: 0,
  captureTime: 0
};

const ModelHub = ({ onClose, onInferenceToggle, isInferenceRunning }) => {
  const [isExiting, setIsExiting] = useState(false);
  const [showDebugView, setShowDebugView] = useState(false);
  const [selectedModelForDebug, setSelectedModelForDebug] = useState(null);
  const [debugData, setDebugData] = useState({
    original: null,
    preprocessed: null,
    detection: null,
    metrics: DEFAULT_METRICS
  });

  const handleDebugClick = (modelName) => {
    console.log('Debug view opened for model:', modelName);
    setSelectedModelForDebug(modelName);
    setShowDebugView(true);
    setDebugData({
      original: null,
      preprocessed: null,
      detection: null,
      metrics: DEFAULT_METRICS
    });
  };

  const handleDebugClose = () => {
    console.log('Closing debug view');
    setShowDebugView(false);
    setSelectedModelForDebug(null);
  };

  // Listen for inference debug events
  useEffect(() => {
    if (!showDebugView) return;

    console.log('Setting up debug view for model:', selectedModelForDebug);

    const handleDebugEvent = (e) => {
      const { type, modelName, debugData: newDebugData, timings } = e.detail;
      console.log('Debug event received:', { type, modelName, newDebugData, timings });
      
      // Only process events for the selected model
      if (modelName !== selectedModelForDebug) return;
      
      if (type === 'inferenceResult' && newDebugData) {
        console.log('Updating debug data with:', {
          original: {
            width: newDebugData.original?.width,
            height: newDebugData.original?.height,
            dataLength: newDebugData.original?.data?.length
          },
          preprocessed: {
            width: newDebugData.preprocessed?.width,
            height: newDebugData.preprocessed?.height,
            dataLength: newDebugData.preprocessed?.data?.length
          },
          detection: {
            width: newDebugData.detection?.width,
            height: newDebugData.detection?.height,
            dataLength: newDebugData.detection?.data?.length
          }
        });

        requestAnimationFrame(() => {
          setDebugData(prev => ({
            ...prev,
            original: newDebugData.original,
            preprocessed: newDebugData.preprocessed,
            detection: newDebugData.detection,
            metrics: {
              totalTime: timings?.totalTime || 0,
              fps: Math.round(1000 / (timings?.totalTime || 1000)),
              memory: performance?.memory?.usedJSHeapSize / 1024 / 1024 || 0,
              preprocessTime: timings?.preprocessTime || 0,
              inferenceTime: timings?.inferenceTime || 0,
              captureTime: timings?.captureTime || 0,
              postprocessTime: timings?.postprocessTime || 0
            }
          }));
        });
      }
    };

    window.addEventListener('inferenceDebug', handleDebugEvent);
    console.log('Added debug event listener');

    return () => {
      window.removeEventListener('inferenceDebug', handleDebugEvent);
      console.log('Removed debug event listener');
    };
  }, [showDebugView, selectedModelForDebug]);

  // Update canvases when debug data changes
  useEffect(() => {
    if (!showDebugView || !debugData) return;

    console.log('Debug data received:', {
      original: debugData.original ? {
        width: debugData.original.width,
        height: debugData.original.height,
        dataLength: debugData.original.data?.length,
        sampleValues: Array.from(debugData.original.data?.slice(0, 9) || [])
      } : null,
      preprocessed: debugData.preprocessed ? {
        width: debugData.preprocessed.width,
        height: debugData.preprocessed.height,
        dataLength: debugData.preprocessed.data?.length,
        sampleValues: Array.from(debugData.preprocessed.data?.slice(0, 9) || [])
      } : null,
      detection: debugData.detection ? {
        width: debugData.detection.width,
        height: debugData.detection.height,
        dataLength: debugData.detection.data?.length,
        sampleValues: Array.from(debugData.detection.data?.slice(0, 9) || [])
      } : null
    });

    const updateCanvas = (stage, data) => {
      if (!data) {
        console.log(`No data for stage: ${stage}`);
        return;
      }
      
      const canvas = document.getElementById(`debug-${stage}`);
      if (!canvas) {
        console.log(`Canvas not found for stage: ${stage}`);
        return;
      }

      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      if (!ctx) {
        console.log(`Could not get 2d context for stage: ${stage}`);
        return;
      }

      const { data: imageData, width, height } = data;
      console.log(`Processing ${stage} canvas:`, { 
        width, 
        height, 
        dataLength: imageData?.length,
        sampleValues: Array.from(imageData?.slice(0, 9) || []),
        canvasWidth: canvas.width,
        canvasHeight: canvas.height
      });

      if (!imageData || !width || !height) {
        console.log(`Missing required data for stage: ${stage}`);
        return;
      }

      try {
        // Convert RGB to RGBA by adding alpha channel
        const rgbaData = new Uint8ClampedArray(width * height * 4);
        for (let i = 0, j = 0; i < imageData.length; i += 3, j += 4) {
          rgbaData[j] = imageData[i];       // R
          rgbaData[j + 1] = imageData[i + 1]; // G
          rgbaData[j + 2] = imageData[i + 2]; // B
          rgbaData[j + 3] = 255;             // A (fully opaque)
        }

        // Create ImageData with RGBA data
        const imgData = new ImageData(rgbaData, width, height);

        // Scale to fit canvas while maintaining aspect ratio
        const scale = Math.min(
          canvas.width / width,
          canvas.height / height
        );
        const scaledWidth = width * scale;
        const scaledHeight = height * scale;
        const x = (canvas.width - scaledWidth) / 2;
        const y = (canvas.height - scaledHeight) / 2;

        // Clear canvas
        ctx.fillStyle = '#000';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Create temporary canvas for scaling
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = width;
        tempCanvas.height = height;
        const tempCtx = tempCanvas.getContext('2d');
        tempCtx.putImageData(imgData, 0, 0);

        // Draw scaled image
        ctx.drawImage(tempCanvas, x, y, scaledWidth, scaledHeight);
        
        console.log(`Successfully updated canvas for stage: ${stage}`);
      } catch (error) {
        console.error(`Error updating canvas for stage ${stage}:`, error);
      }
    };

    requestAnimationFrame(() => {
      if (debugData.original) updateCanvas('original', debugData.original);
      if (debugData.preprocessed) updateCanvas('preprocessed', debugData.preprocessed);
      if (debugData.detection) updateCanvas('detection', debugData.detection);
    });
  }, [debugData, showDebugView]);

  const handleClose = useCallback(() => {
    setIsExiting(true);
    setTimeout(() => {
      onClose();
    }, 400);
  }, [onClose]);

  const handleModelSelect = (modelName) => {
    onInferenceToggle(modelName);
  };

  if (showDebugView) {
    const { metrics = DEFAULT_METRICS } = debugData;
    return (
      <div className={`${styles.modelHub} ${styles.debugView}`}>
        <button className={styles.backButton} onClick={handleDebugClose}>
          <FaArrowLeft /> Back to Models
        </button>
        
        <div className={styles.debugTitle}>
          {selectedModelForDebug} Debug View
        </div>

        <div className={styles.debugControls}>
          <span className={styles.debugStatus}>
            <FaBrain style={{ color: '#00F135' }} /> Live Inference
          </span>
          <span className={styles.debugMetricBadge}>
            <FaChartLine /> {metrics?.fps || 0} FPS
          </span>
          <span className={styles.debugMetricBadge}>
            <FaMicrochip /> {Math.round(metrics?.memory || 0)}MB
          </span>
        </div>

        <div className={styles.debugContainer}>
          <div className={styles.debugStage}>
            <h4>
              <span>Original Frame</span>
              <div className={styles.stageMetrics}>
                <span className={styles.stageTiming}>
                  <span className={styles.stageTimingLabel}>Capture</span>
                  <span className={styles.stageTimingValue}>
                    {(metrics?.captureTime || 0).toFixed(1)}ms
                  </span>
                </span>
                <span className={styles.stageResolution}>
                  {debugData.original?.width || 0}x{debugData.original?.height || 0}
                </span>
              </div>
            </h4>
            <canvas 
              id="debug-original" 
              width="240" 
              height="135" 
              className={styles.debugCanvas}
            />
          </div>
          
          <div className={styles.debugStage}>
            <h4>
              <span>Preprocessed Frame</span>
              <div className={styles.stageMetrics}>
                <span className={styles.stageTiming}>
                  <span className={styles.stageTimingLabel}>Preprocess</span>
                  <span className={styles.stageTimingValue}>
                    {(metrics?.preprocessTime || 0).toFixed(1)}ms
                  </span>
                </span>
                <span className={styles.stageResolution}>
                  {debugData.preprocessed?.width || 0}x{debugData.preprocessed?.height || 0}
                </span>
              </div>
            </h4>
            <canvas 
              id="debug-preprocessed" 
              width="240" 
              height="135" 
              className={styles.debugCanvas}
            />
          </div>
          
          <div className={styles.debugStage}>
            <h4>
              <span>Detection Results</span>
              <div className={styles.stageMetrics}>
                <span className={styles.stageTiming}>
                  <span className={styles.stageTimingLabel}>Inference</span>
                  <span className={styles.stageTimingValue}>
                    {(metrics?.inferenceTime || 0).toFixed(1)}ms
                  </span>
                </span>
                <span className={styles.stageTiming}>
                  <span className={styles.stageTimingLabel}>Postprocess</span>
                  <span className={styles.stageTimingValue}>
                    {(metrics?.postprocessTime || 0).toFixed(1)}ms
                  </span>
                </span>
              </div>
            </h4>
            <canvas 
              id="debug-detection" 
              width="240" 
              height="135" 
              className={styles.debugCanvas}
            />
          </div>

          <div className={styles.debugMetrics}>
            <h4>Pipeline Performance</h4>
            <div className={styles.metricsContainer}>
              <div className={styles.metricItem}>
                <span>Total Pipeline</span>
                <span className={styles.metricValue}>
                  {(metrics?.totalTime || 0).toFixed(1)}ms
                </span>
              </div>
              <div className={styles.metricItem}>
                <span>FPS</span>
                <span className={styles.metricValue}>
                  {metrics?.fps || 0}
                </span>
              </div>
              <div className={styles.metricItem}>
                <span>Memory Usage</span>
                <span className={styles.metricValue}>
                  {Math.round(metrics?.memory || 0)}MB
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.modelHub} ${isExiting ? styles.exiting : ''}`}>
      <button className={styles.backButton} onClick={handleClose}>
        <FaArrowLeft /> Back
      </button>
      
      <div className={styles.modelList}>
        <div className={`${styles.modelCard} ${isInferenceRunning === 'MobileNetV2' ? styles.active : ''}`}>
          <img src="assets/images/mobilenetv2.png" alt="MobileNetV2" className={styles.modelImage} />
          
          <div className={styles.modelInfo}>
            <h3>MobileNetV2</h3>
            <p className={styles.modelDescription}>Efficient real-time classification model</p>
            
            <div className={styles.modelStatus}>
              <FaBrain style={{ color: isInferenceRunning === 'MobileNetV2' ? '#00F135' : '#666' }} />
              <span>{isInferenceRunning === 'MobileNetV2' ? 'Running' : 'Ready'}</span>
            </div>
            
            <div className={styles.modelStats}>
              <div className={styles.stat}>
                <FaMicrochip />
                <span>Light-weight</span>
              </div>
              <div className={styles.stat}>
                <FaChartLine />
                <span>Fast Inference</span>
              </div>
            </div>
          </div>

          <div className={styles.modelActions}>
            <button className={styles.learnMore}>
              Learn More
            </button>
            <button 
              className={`${styles.select} ${isInferenceRunning === 'MobileNetV2' ? styles.active : ''}`}
              onClick={() => handleModelSelect('MobileNetV2')}
            >
              {isInferenceRunning === 'MobileNetV2' ? 'Stop Inference' : 'Start Inference'}
            </button>
            <button 
              className={`${styles.debug} ${isInferenceRunning === 'MobileNetV2' ? '' : styles.disabled}`}
              onClick={() => handleDebugClick('MobileNetV2')}
              disabled={isInferenceRunning !== 'MobileNetV2'}
            >
              <FaBug /> Debug View
            </button>
          </div>
        </div>

        <div className={`${styles.modelCard} ${isInferenceRunning === 'YOLOv10n' ? styles.active : ''}`}>
          <img src="assets/images/yolov10.jpg" alt="YOLOv10n" className={styles.modelImage} />
          
          <div className={styles.modelInfo}>
            <h3>YOLOv10n</h3>
            <p className={styles.modelDescription}>Nano-sized real-time object detection model</p>
            
            <div className={styles.modelStatus}>
              <FaBrain style={{ color: isInferenceRunning === 'YOLOv10n' ? '#00F135' : '#666' }} />
              <span>{isInferenceRunning === 'YOLOv10n' ? 'Running' : 'Ready'}</span>
            </div>
            
            <div className={styles.modelStats}>
              <div className={styles.stat}>
                <FaMicrochip />
                <span>Nano Size</span>
              </div>
              <div className={styles.stat}>
                <FaChartLine />
                <span>Real-time Detection</span>
              </div>
            </div>
          </div>

          <div className={styles.modelActions}>
            <button className={styles.learnMore}>
              Learn More
            </button>
            <button 
              className={`${styles.select} ${isInferenceRunning === 'YOLOv10n' ? styles.active : ''}`}
              onClick={() => handleModelSelect('YOLOv10n')}
            >
              {isInferenceRunning === 'YOLOv10n' ? 'Stop Inference' : 'Start Inference'}
            </button>
            <button 
              className={`${styles.debug} ${isInferenceRunning === 'YOLOv10n' ? '' : styles.disabled}`}
              onClick={() => handleDebugClick('YOLOv10n')}
              disabled={isInferenceRunning !== 'YOLOv10n'}
            >
              <FaBug /> Debug View
            </button>
          </div>
        </div>

        <div className={`${styles.modelCard} ${styles.disabled}`}>
          <img src="assets/images/filler.png" alt="Coming Soon" className={styles.modelImage} />
          
          <div className={styles.modelInfo}>
            <h3>More Models Coming Soon</h3>
            <p className={styles.modelDescription}>Additional AI models will be available in future updates</p>
            
            <div className={styles.modelStatus}>
              <FaBrain style={{ color: '#666' }} />
              <span>Not Available</span>
            </div>
          </div>

          <div className={styles.modelActions}>
            <button className={styles.learnMore} disabled>
              Learn More
            </button>
            <button className={styles.select} disabled>
              Not Available
            </button>
            <button className={styles.debug} disabled>
              <FaBug /> Debug View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelHub; 